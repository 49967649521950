import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { AppService, PageComponentService } from '../services';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PageComponentResolver implements Resolve<any> {
  constructor(private appService: AppService, private pgComp: PageComponentService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any{
    console.log("the route: ",state.url)
    let page = this.pgComp.getPageId(state.url);
    console.log("the page id: ",page)
    return this.appService.getPageComponent(page).pipe(map((res: any) => {
      return res&&res["componentsSlug"]?res["componentsSlug"]:[];
    }));
  }
}