import { Injectable, Inject } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
} from "@angular/common/http";
import { map } from "rxjs/operators";
import { DataService } from "../../services/data.service";

@Injectable({
  providedIn: "root",
})
export class AmlSearchService {
  relation: any[] = [
    "JURADICTION_OF",
    "DETAIL_INFO",
    "OFFICER_OF",
    "PSC_OF",
    "ADDRESS",
    "Category",
    "SubCategory",
    "From_Source",
    "Primary_Country_of",
    "Allegation_Country_of",
    "Son",
    "Father",
    "Associate",
  ];

  labels: any[] = [
    "Country",
    "Juradiction",
    "Entity",
    "Organization",
    "Individual",
    "Address",
    "Detail",
    "Category",
    "SubCategory",
    "DataSource",
  ];

  constructor(
    private http: HttpClient,
    private _data: DataService,
    @Inject("API_URL") public API_URL: any
  ) {}

  doSearch(dataQuery) {
    return this.http.post<any>(`${this.API_URL}/v2/aml/new-search`, dataQuery).pipe(
      map(res => {
        return res;
      })
    );
  }

  getSearchFilters(dataQuery) {
    return this.http.post<any>(`${this.API_URL}/v2/aml/filter`, dataQuery).pipe(
      map(res => {
        return res;
      })
    );
  }

  getDetailedRecord(guid) {
    return this.http.get<any>(`${this.API_URL}/v2/aml/search/${guid}`).pipe(
      map(res => {
        return res;
      })
    );
  }

  lvlOneGraphData(dataQuery) {
    return this.http
      .post<any>(`${this.API_URL}/v1/aml/data/v1`, dataQuery)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getGraphFilters(dataQuery) {
    return this.http
      .post<any>(`${this.API_URL}/v1/aml/data/filter`, dataQuery)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getNodeLvlGraph(nodeId) {
    return this.http.get<any>(`${this.API_URL}/v1/aml/data/${nodeId}`).pipe(
      map(res => {
        return res;
      })
    );
  }

  getNodeRelation(dataQuery) {
    return this.http
      .post<any>(`${this.API_URL}/v1/aml/data/id/relation`, dataQuery)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  pushToArray(arr, obj) {
    const index = arr.findIndex(e => e.id === obj.id);
    if (index === -1) {
      arr.push(obj);
    } else {
      arr[index] = obj;
    }
  }

  // pushToEdge(arr, obj) {
  //   const index = arr.findIndex(e => {
  //     e.from === obj.from && e.to == obj.to;
  //   });
  //   if (index === -1) {
  //     arr.push(obj);
  //   } else {
  //     arr[index] = obj;
  //   }
  // }

  pushToEdge(nodes,arr, obj) {
    var final = [];
    var relations = [];
    arr.forEach(e=>{
      if(e.target === obj){
        if(e.label == 'DETAIL_INFO'){
          var index = nodes.findIndex(n => { return n.nodeId == e.source});
          nodes.splice(index,1);
        }else{
          final.push(e);
        } 
      }else{
        var index = nodes.findIndex(n => { return n.nodeId == e.target});
        var data = Object.create({});
        data.fullName = nodes[index].fullName;
        data.relation = e.label;
        relations.push(data);
      }
    })
    return {final:final , relations: relations};
  }

  // Get all saved risk policies of client
  getSavedRiskPolicies() {
    return this.http.get<any>(`${this.API_URL}/riskpolicy`).pipe(
      map(res => {
        return res;
      })
    );
  }
  //aml search save
  saveAMLSearchData(dataQuery){
    return this.http.post<any>(`${this.API_URL}/v2/aml/save/search`,dataQuery).pipe(
      map(res =>{
        return res;
      })
    )
  }
  submitAMLSearchData(dataSubmitQuery,id){
    return this.http.post<any>(`${this.API_URL}/amlchecks/v2/results/${id}/submit`,dataSubmitQuery).pipe(
      map(res =>{
        return res;
      })
    )
  }
  
  //akash : save and exit API 
  saveAMLData(dataQuery){
    return this.http.post<any>(`${this.API_URL}/amlalerts/save`,dataQuery).pipe(
      map(res =>{
        return res;
      })
    )
  }


  getBasicProfileSearch(payload){
    return this.http.post<any>(`${this.API_URL}/v1/profile/basic/search`,payload);
  }

  getFullProfileSearch(payload){
    return this.http.post<any>(`${this.API_URL}/v1/profile/search`,payload);
  }

  ittsArticle(id){
    return this.http.get(`${this.API_URL}/v1/profile/article/${id}`).pipe(map((res: any) => res.data));
  }

  getRecentArticles(payload){
    return this.http.post<any>(`${this.API_URL}/adversemedia/get-articles`,payload);
  }
 
}
