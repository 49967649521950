// export class SelectedAddMessage{
//     frontPhotoMessage : string;
//     rearPhotoMessage  : string;
//     constructor() {
//       this.frontPhotoMessage = "" ;
//       this.rearPhotoMessage = "" ;
//     }
// }

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService, AppService, TrackingService } from "../../../services";
import { first } from 'rxjs/operators';

import { Address } from "../../../models/Address.model";
import { UserDoc } from "../../../models/UserDoc.model";

import { Verification } from "../../../models/Verification.model";
import { MatchAttributeStatus, VerificationStates } from "../../../utils/status";

import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { KycModalComponent } from '../../../shared/kyc-modal/kyc-modal.component';
@Component({
	selector: 'app-address-check',
	templateUrl: './address-check.component.html',
	styleUrls: ['./address-check.component.scss']
})
export class AddressCheckComponent implements OnInit {
	// For individual componentizing of Checks
	// @Input() endUserCheck: any ;

	// For cumulative handling of Address Checks
	@Input() usersIDChecks: any[];
	@Input() basicUserData: any;
	endUserCheck: any //only for address-doc-check

	@Input() hashId: any;
	@Input() allowedDocs: any;
	permanentAddress: Address;
	correspondenceAddress: Address;
	countries: any;

	@Input() traversalState: any;
	//Emitting the current state of the Selected Check
	@Output() checkState: EventEmitter<any> = new EventEmitter();
	emitState: any = {};
	selectedAddress: string;

	// selectedAddMessage   : SelectedAddMessage = new SelectedAddMessage();

	// addressDoc			 : UserDoc ;
	addressDoc: any = {};

	//boolean value to check if ID upload process is fresh or ID already uploaded.
	//Further params for verification component
	isIDUpdate: boolean = false;
	verification: Verification;

	endUserDocInfo: any = null;

	knOptions = {
		readOnly: true,
		size: 140,
		unit: "%",
		textColor: "#ffffff",
		fontSize: "18",
		fontWeigth: "400",
		fontFamily: "Roboto",
		valueformat: "percent",
		value: 0,
		max: 250,
		trackWidth: 15,
		barWidth: 15,
		trackColor: "#e1e1e1",
		barColor: "#ff0000"
	};

	//OCR result
	ocrdata: any = {};
	faceUrl: any = null;

	// states                  : any[] = [];

	@Input() selectedCountry: any;

	extAllowed: any[] = ["image/png", "image/jpeg", "image/jpg"];

	bsModalRef: BsModalRef;
	deviceInfo: any;
	ip: any;

	constructor(
		private modalService: BsModalService,
		private _data: DataService,
		private _app: AppService,
		private _track: TrackingService,
		public router: Router) {
	}

	ngOnInit() {
		this.verification = new Verification();
		this.permanentAddress = new Address();
		this.correspondenceAddress = new Address();
		let endUserCheck = this.usersIDChecks.filter(obj => obj.standardCheck.slug == "address-doc-check");
		this.endUserCheck = endUserCheck.length ? endUserCheck[0] : null;

		let allCountries = this._data.getCookieObjectByName("allCountries");
		if (!allCountries) {
			this.getAllCountries();
		}
		else {
			this.countries = allCountries;
		}

		//for Docs
		// this.addressDoc = new UserDoc();
		this.addressDoc["docType"] = "ADDRESS";
		this.addressDoc["docSerialNo"] = "";
		this.addressDoc["docIssueDate"] = "";
		this.addressDoc["docExpiryDate"] = "";
		this.addressDoc["firstNameAsPerDoc"] = "";
		this.addressDoc["middleNameAsPerDoc"] = "";
		this.addressDoc["lastNameAsPerDoc"] = "";
		this.addressDoc["isNameInDocDiff"] = "";
		// this.addressDoc["provinceCode"]       =  "";
		// NOTE : comment the below function caLL
		// this.getUserDocsDetails();
		this.getUserAddress();
	}

	//Get User Doc Details , check if it already exists
	getUserDocsDetails() {
		this._data.changeLoaderVisibility(true);
		let checkGuid = this.endUserCheck["endUserCheckID"];
		this._app.getUserDocsDetails(checkGuid)
			.subscribe(
				(res) => {
					console.log(res);
					this._data.changeLoaderVisibility(false);
					if (res.data) {
						this.isIDUpdate = true;
						this.getDocFromServer(checkGuid);
						this.setResultFromAPI(res);
					}
					else {
						this.isIDUpdate = false;
						this.addressDoc = new UserDoc();
						this.addressDoc["docType"] = "ADDRESS";
					}
				},
				(err) => {
					this.isIDUpdate = false;
					this.addressDoc = new UserDoc();
					this.addressDoc["docType"] = "ADDRESS";
				}
			);
	}

	getUserAddress() {
		this._app.getUserAddress(this.hashId)
			.subscribe(
				(res) => {
					let address = res.data;
					if (address && address.length) {
						this.permanentAddress = address.filter(obj => obj.addressType == "PERMANENT")[0];
						this.correspondenceAddress = address.filter(obj => obj.addressType == "CORRESPONDENCE")[0];
					}
					else {
						this.permanentAddress = new Address();
						this.correspondenceAddress = new Address();
					}
				}
			)
	}

	copyPermAddress() {
		this.correspondenceAddress = Object.assign({}, this.permanentAddress);
	}

	getDocFromServer(checkGuid) {
		this._app.getDocFromServer(checkGuid)
			.subscribe(
				(res) => {
					this.addressDoc["docFrontUrl"] = `data:image/jpeg;base64,${res.data["frontDoc"]}`;
					this.addressDoc["docRearUrl"] = `data:image/jpeg;base64,${res.data["rearDoc"]}`;
				}
			);
	}

	//for populating reuslt & showing verification status/result
	setResultFromAPI(res) {
		this.addressDoc["docExpiryDate"] = res.data.endUserDocInfo["docExpiryDate"];
		// this.addressDoc["docFrontUrl"]     = res.data.endUserDocInfo["docFrontUrl"];
		this.addressDoc["docIssueDate"] = res.data.endUserDocInfo["docIssueDate"];
		// this.addressDoc["docSlug"]         = res.data.endUserDocInfo["docSlug"];
		this.addressDoc["docSlug"] = res.data.endUserDocInfo.documentInfo["docSlug"];
		// this.addressDoc["docRearUrl"]      = res.data.endUserDocInfo["docRearUrl"];
		this.addressDoc["docSerialNo"] = res.data.endUserDocInfo["docSerialNo"];
		this.addressDoc["firstNameAsPerDoc"] = res.data.endUserDocInfo["firstNameAsPerDoc"];
		this.addressDoc["middleNameAsPerDoc"] = res.data.endUserDocInfo["middleNameAsPerDoc"];
		this.addressDoc["lastNameAsPerDoc"] = res.data.endUserDocInfo["lastNameAsPerDoc"];
		this.addressDoc["isNameInDocDiff"] = res.data.endUserDocInfo["isNameInDocDiff"];
		// this.addressDoc["provinceCode"]       = res.data.endUserDocInfo.province["stateCode"];

		this.addressDoc["docType"] = "ADDRESS";

		this.verification["verificationResult"] = res.data["verificationResult"];
		this.verification["verificationScore"] = res.data["verificationScore"];
		this.verification["verificationStatus"] = res.data["verificationStatus"];
		console.log(this.verification);

		this.endUserDocInfo = res.data["endUserDocInfo"];
		this.ocrdata = res.data["ocrdata"];
		this.selectedAddress = res.data.endUserDocInfo.documentInfo["docName"];
		// this.faceUrl = res.data["faceUrl"];
		// this.permanentAddress = res.data["permanentAddress"];
		// this.correspondenceAddress = res.data["correspondenceAddress"];
	}

	getAllCountries() {
		this._app.getAllCountries()
			.pipe(first())
			.subscribe(
				(res) => {
					this.countries = res["data"],
						this._data.setCookieObject("allCountries", this.countries);
					// if(this.selectedCountry){
					// 	let countryCode = this.countries.filter(obj=>obj.countryName == this.selectedCountry) ;
					// 	let countryObj = {
					//         "countryCode": countryCode[0].countryCode 
					//      	}
					// 	this._app.getProvinceList(countryObj)
					// 	.subscribe(
					// 		(res)=>{ console.log(res); this.states = res.data ;}
					// 	);
					// }

				},
				(err) => { console.log(err) }
			);
	}

	saveAddress() {
		console.log(this.permanentAddress);
		console.log(this.correspondenceAddress);
		let addressObj = {
			"permanentAddress": this.permanentAddress,
			"correspondenceAddress": this.correspondenceAddress
		};
		var message = null;
		if (!this.permanentAddress.addressLine1) {
			message = "Please Enter proper Adress Line 1"
		} else if (!this.permanentAddress.addressLine2) {
			message = "Please Enter proper Adress Line 1"
		} else if (!this.permanentAddress.landmark) {
			message = "Please Enter proper Landmark"
		} else if (!this.correspondenceAddress.addressLine1) {
			message = "Please Enter proper Correspondence Address Adress Line 1"
		} else if (!this.correspondenceAddress.addressLine2) {
			message = "Please Enter proper Correspondence Address Adress Line 1"
		} else if (!this.correspondenceAddress.landmark) {
			message = "Please Enter proper Correspondence Address Landmark"
		} else if (!this.addressDoc['docFrontUrl']) {
			message = "Please Uplaod a document"
		}
		this._app.saveUserAddress(addressObj, this.hashId)
			.subscribe(
				(res) => {
					console.log(res);
					this._data.toastrShow(message || res["message"], "info");
					this.saveAddressDocs();
				},
				(err) => {

				}
			);
	}

	selectAddressType(doc) {
		this.selectedAddress = doc.docName;
		this.addressDoc.docSlug = doc.docSlug;
		console.log(this.selectedAddress);
	}

	shouldShowInputField(attr) {
		let selectedDocSlug = this.addressDoc["docSlug"];
		let allowedAddress = this.allowedDocs.validAddressDocs;
		let isAttrAllowed = allowedAddress.filter(obj => obj.docSlug == selectedDocSlug);
		if (isAttrAllowed.length) {
			if (isAttrAllowed[0].attributes.indexOf(attr) >= 0) { //mandatoryField
				return true;
			}
			else {
				return false;
			}
		}
	}

	fileChangeListener($event, docSlug, docUrlType) {
		var element = <HTMLInputElement>document.getElementById('filename');
		var image: any = new Image();
		var file: File = $event[0] ? $event[0] : $event.target.files[0];
		var name = file.name;
		console.log(name);
		console.log(file);
		if (file && this.extAllowed.indexOf(file.type) >= 0) {
			var reader = new FileReader();
			var that = this;
			that.openCropModal($event, docSlug, docUrlType);
			element.value = null;

			// reader.onloadend = function() {
			// 	// console.log('RESULT', reader.result)
			// 	that.addressDoc[docUrlType] = reader.result ;
			// 	that.addressDoc["docSlug"] = docSlug ;
			// 	console.log(that.addressDoc);
			// }
			// reader.readAsDataURL(file);
		} else if (file.type === "application/pdf") {
			console.log("Selected pdf");
			var fileReader = new FileReader();
			var base64;
			var that = this;
			// Onload of file read the file content
			fileReader.onload = function (fileLoadedEvent) {
				base64 = fileReader.result;
				// Print data in console
				//   console.log(base64);
				// that.idDoc[docUrlType] = base64;
				that.addressDoc[docUrlType + "Pdf"] = { name: file.name, file: base64 };
				element.value = null;
			};
			// Convert data to base64
			fileReader.readAsDataURL(file);
			// this.idDoc[docUrlType] = result["image"];
			this.addressDoc["docSlug"] = docSlug;
		} else {
			this._data.toastrShow(
				"File extension not supported , we support png , jpeg , jpg & pdf.",
				"error"
			);
		}
	}

	onFilesChange($event, docSlug, docUrlType) {
		this.fileChangeListener($event, docSlug, docUrlType);
	}

	openCropModal(event, docSlug, docUrlType) {
		const initialState = {
			title: `Crop Image`,
			type: "crop-image",
			loadedImageEvent: event
		};
		this.bsModalRef = this.modalService.show(KycModalComponent, {
			initialState,
			class: "modal-lg"
		});
		this.bsModalRef.content.closeBtnName = "Close";
		console.log(this.bsModalRef.content.onClose);
		this.bsModalRef.content.onClose.subscribe(result => {
			// console.log("results", result);
			if (result && Object.keys(result).length) {
				// console.log(result);
				this.addressDoc[docUrlType] = result["image"];
				this.addressDoc["docSlug"] = docSlug;
			}
		});
	}

	saveAddressDocs() {
		if (!this.checkForDateValidations()) {
			return;
		}
		// this.setEmitState("completed");
		let countryCode = this.countries.filter(obj => obj.countryName == this.selectedCountry);
		this.addressDoc["countryCode"] = countryCode[0].countryCode;
		const apiUrl = `userdocs/upload`;
		this._data.changeLoaderVisibility(true);
		console.log(this.addressDoc);
		if (this.addressDoc['docFrontUrlPdf']) {
			this.addressDoc['docFrontUrl'] = this.addressDoc['docFrontUrlPdf'].file;
		}
		this._app.saveUserDoc(apiUrl, this.addressDoc, this.endUserCheck["endUserCheckID"])
			.subscribe(
				async (res) => {
					console.log(res);
					this._data.changeLoaderVisibility(false);
					this._data.toastrShow(res["message"], "info");
					this.setResultFromAPI(res);
					this.getUserAddress();
					await this._track.activityLog();
					// this.setEmitState("completed");
				},
				(err) => {
					// this.setEmitState("failed");
				}
			);
	}


	checkForDateValidations() {
		console.log(this.basicUserData);
		var dob = (this.basicUserData && this.basicUserData["dob"]) ? new Date(this.basicUserData["dob"]) : null;
		var today = new Date();
		if (this.shouldShowInputField("SN") && !this.addressDoc["docSerialNo"]) {
			this._data.toastrShow("Serial Number Cannot be Empty", "error");
			return false;
		}
		if (this.shouldShowInputField("ISSUEDATE") && !this.addressDoc["docIssueDate"]) {
			this._data.toastrShow("Issue Date is Required", "error");
			return false;
		}
		if (this.shouldShowInputField("ISSUEDATE") && this.addressDoc["docIssueDate"]) {
			var docIssueDate = new Date(this.addressDoc["docIssueDate"]);
			if (docIssueDate < dob) {
				this._data.toastrShow(`Issue Date cannot be below User's DOB , ${dob}`, "error");
				return false;
			}
			if (docIssueDate > today) {
				this._data.toastrShow(`Issue Date cannot greater than Present Date`, "error");
				return false;
			}
		}
		if (this.shouldShowInputField("EXPIRYDATE") && !this.addressDoc["docExpiryDate"]) {
			this._data.toastrShow("Expiry Date is Required", "error");
			return false;
		}
		if (this.shouldShowInputField("EXPIRYDATE") && this.addressDoc["docExpiryDate"]) {
			var docExpiryDate = new Date(this.addressDoc["docExpiryDate"]);
			if (docExpiryDate < dob) {
				this._data.toastrShow(`Expiry Date cannot be below User's DOB , ${dob}`, "error");
				return false;
			}
			if (docExpiryDate < today) {
				this._data.toastrShow(`Expiry Date should be greater than Present Date`, "error");
				return false;
			}
		}
		if (this.shouldShowInputField("ISSUEDATE") && this.shouldShowInputField("EXPIRYDATE")) {
			var docIssueDate = new Date(this.addressDoc["docIssueDate"]);
			var docExpiryDate = new Date(this.addressDoc["docExpiryDate"]);
			if (docExpiryDate < docIssueDate) {
				this._data.toastrShow(`Issue Date cannot greater than Expiry Date`, "error");
				return false;
			}
		}

		// if(this.shouldShowInputField("state") && !this.addressDoc.provinceCode){
		//      	this._data.toastrShow(`Please Select the State`,"error");
		//        return false;
		//    }
		return true;
	}

	updateCheckState($event) {
		this.checkState.emit($event);
	}

	setEmitState(state) {
		this.emitState["standardCheckGroup"] = this.endUserCheck["standardCheckGroup"];
		this.emitState["endUserCheckID"] = this.endUserCheck["endUserCheckID"];
		if (state == "completed") {
			this.emitState["state"] = "completed";
		}
		else if (state == "failed") {
			this.emitState["state"] = "failed";
		}
		this.checkState.emit(this.emitState);
	}


}
