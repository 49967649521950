import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scoreRiskStatus'
})
export class ScoreRiskStatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(value >= 81 && value <= 100){
      return 'Very High'
    }else if(value >= 61 && value <= 80){
      return 'High'
    }else if(value >= 41 && value <= 60){
      return 'Medium High'
    }else if(value >= 21 && value <= 40){
      return 'Medium Low'
    }else{
      return 'Low'
    }
  }
}
