import {
  Component,
  OnInit,
  ChangeDetectorRef,
  HostListener
} from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { KycModalComponent } from "./shared/kyc-modal/kyc-modal.component";
import { DataService } from "./services/data.service";
import { MessagingService } from "./services/messaging.service";
import { AppService } from "./services/app.service";
import { TrackingService } from "./services/tracking.service";
import { TourService } from "./services/tour.service";
import { PaymentService } from "./services/payment.service";
import { NotfoundComponent } from "./shared/notfound/notfound.component";
import { termsAndCondition } from "./utils/terms-and-condition";

// import { PushNotificationService } from "ng-push-notification";
// import { SwUpdate } from '@angular/service-worker';

declare var window;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  title = "kyc-adminto";
  shouldShow: boolean = true;
  nonGuardedRoutes = [
    "/login",
    "/register",
    "/reset-password",
    "/setup-account",
    "/forgot-password",
    "/verify-email",
    "/404",
    // "/subscribe/payment",
    "/services/register",
    "/services/payment",
    "/change-password",
    "/payment"
  ];
  // =======
  //   nonGuardedRoutes = [
  //     "/login",
  //     "/register",
  //     "/reset-password",
  //     "/forgot-password",
  //     "/verify-email",
  //     "/change-password",
  //   ];
  // >>>>>>> d091d859ac81616fa24434d86efd45905089a57b
  currentUrl: string;

  theming: any[] = ["theme-light", "theme-dark", "theme-golden", null];

  currentTheme: string = null;

  //Modal
  bsModalRef: BsModalRef;

  //Universal UI blocker loader
  showLoader: boolean = false;
  showAMLLoader: boolean = false;

  //firebase thing
  message: any;

  currentRoute: string;

  //Tour Vars
  showKycTour: boolean = false;
  tourData: any = null;
  clientData: any;
  termsAndCondition = termsAndCondition;

  @HostListener("window:resize", ["$event"])
  public onResize(event) {
    if (event.target.innerWidth < 1200) {
      this.showKycTour = false;
    }
  }

  // @HostListener('click', ['$event'])
  // public onClick(event){
  //   if(this.showKycTour){
  //     event.stopPropagation();
  //     event.preventDefault();
  //     event.stopImmediatePropagation();
  //     console.log(event);
  //     console.log("Prevent Click Y");
  //   }
  // }

  constructor(
    private modalService: BsModalService,
    private _data: DataService,
    private _app: AppService,
    private _track: TrackingService,
    private _tour: TourService,
    public cdRef: ChangeDetectorRef,
    private _payment: PaymentService,
    // public updates   : SwUpdate,
    public router: Router
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(e => {
        this.showKycTour = false;
        this.currentUrl = e["url"];
        console.log(this.currentUrl);
        // this._track.initiateIntercom();
        // this._track.trackBasicData();
      });
    // console.log(this.nonGuardedRoutes.includes(this.router.url));

    // this.updates.available.subscribe(event => {
    //     console.log('current version is', event.current);
    //     console.log('available version is', event.available);
    // });
    // this.updates.activated.subscribe(event => {
    //     console.log('old version was', event.previous);
    //     console.log('new version is', event.current);
    // });
    // if (this.updates.isEnabled) {

    //     this.updates.available.subscribe(() => {

    //         if(confirm("New version available. Load New Version?")) {
    //           this._data.toastrShow('Refreshing in Background!' , 'info');
    //           window.location.reload();
    //         }
    //     });
    // }
  }

  ngOnInit() {
    let token = this._data.getCookiesByName("token");
    (async () => {
      if (token) {
        await this.getClientProfileData();
        await this.getCurrentSubscription();
        await this.getClientConfig();
      } else {
        console.log("Not Logged In!");
      }

      //Setting default theme
      localStorage.getItem('currentTheme') == null ? this.currentTheme = "theme-light" : this.currentTheme = localStorage.getItem('currentTheme');

      // this._data.startTour();
      this._data.loaderInfo.subscribe(val => {
        this.showLoader = val;
        this.cdRef.detectChanges();
      });

      this._data.loaderInfoAML.subscribe(val => {
        this.showAMLLoader = val;
        this.cdRef.detectChanges();
      });
    })()

    // this.doFirebaseThings();

    // if (this._data.getCookiesByName("token")) {
    //   console.log("token Present");
    //   this.getClientSubscriptionInfo();
    // }
  }

  async getClientConfig() {
    this._app.getClientConfig().subscribe(res => {
      if (res.data) {
        this._data.clientsConfigs.next(res.data);
      }
    });
  }

  async getClientProfileData() {
    this._app.getClientProfileData().subscribe(res => {
      this.clientData = res.data;
      console.log(' Client Profile Data : ', res.data);
      if (!this.clientData.termsAndConditionAccepted) {
        const initialState = {
          title: `Terms And Condition`,
          type: "terms-and-condition",
          termsAndCondition: termsAndCondition
        };
        this.bsModalRef = this.modalService.show(KycModalComponent, {
          initialState,
          class: "modal-lg",
          backdrop: 'static'
        });
        this.bsModalRef.content.closeBtnName = "Close";
        this.bsModalRef.content.onClose.subscribe(isTermsAndCondition => {
          console.log(isTermsAndCondition);
          if (isTermsAndCondition == "enabled") {
            this._app.acceptTermsAndCondition().subscribe(res => {
              this.clientData.termsAndConditionAccepted = true;
              this._data.changeClientData(this.clientData);
            })
          }
        });
      } else {
        this._data.changeClientData(this.clientData);
      }

      if (this.clientData != null && this.clientData.clientVerificationForPayment && this.currentUrl.includes('verify')) this.router.navigate(['home']);
      // console.log(res.data);
    });
  }
  public onRouterOutletActivate(event: any) {
    // console.log(event instanceof NotfoundComponent);
    if (event instanceof NotfoundComponent) {
      this.currentRoute = "404: Page Not Found";
    } else {
      this.currentRoute = this.router.url.slice(1);
      this.currentRoute = this._data.removeQueryParams(this.currentRoute);
      this.currentRoute = this.currentRoute.substring(
        0,
        this.currentRoute.indexOf("/")
      ).length
        ? this.currentRoute.substring(0, this.currentRoute.indexOf("/"))
        : this.currentRoute;
    }
  }

  async getCurrentSubscription() {
    this._payment.getCurrentSubscription().subscribe(res => {
      console.log(' Current SUbscription : ', res);
      let accessibleStandardServices = (res.data) ? res.data.subscriptionPlan.standardServices : [];
      let accessibleStandardCheck = (res.data) ? res.data.subscriptionPlan.standardCheck : [];
      if (accessibleStandardServices && accessibleStandardServices.length) {
        this._data.changeClientStandardServices(accessibleStandardServices);
        this._data.changeClientStandardChecks(accessibleStandardCheck);
        console.log(
          ":::::::::::::::::::::::::::::::::",
          res.data.subscriptionPlan.planCategory
        );
        console.log(accessibleStandardServices);
      }
    })
  }
  // getClientSubscriptionInfo() {
  //   this._app.getClientSubscriptionInfo().subscribe(res => {
  //     let accessibleStandardServices =
  //       res.data.subscriptionPlan.standardServices;
  //     if (accessibleStandardServices && accessibleStandardServices.length) {
  //       this._data.setCookieObject(
  //         "standardServices",
  //         accessibleStandardServices
  //       );
  //       this._data.setCookieByName(
  //         "planCategory",
  //         res.data.subscriptionPlan.planCategory
  //       );
  //       console.log(
  //         ":::::::::::::::::::::::::::::::::",
  //         res.data.subscriptionPlan.planCategory
  //       );
  //       console.log(accessibleStandardServices);
  //       // this._data.changeClientStandardServices(accessibleStandardServices);
  //     }
  //   });
  // }

  // getClientSubscriptionInfo() {
  //   this._app.getClientSubscriptionInfo().subscribe(res => {
  //     let accessibleStandardServices =
  //       res.data.subscriptionPlan.standardServices;
  //     if (accessibleStandardServices && accessibleStandardServices.length) {
  //       this._data.setCookieObject(
  //         "standardServices",
  //         accessibleStandardServices
  //       );
  //       this._data.setCookieByName(
  //         "planCategory",
  //         res.data.subscriptionPlan.planCategory
  //       );
  //       console.log(res.data.subscriptionPlan.planCategory);
  //       console.log(accessibleStandardServices);
  //       this._data.changeClientStandardServices(accessibleStandardServices);
  //     }
  //   });
  // }

  showPage(currentUrl) {
    // console.log("showPage Function");
    // let current = currentUrl.substring(0, currentUrl.indexOf("?")).length
    //   ? currentUrl.substring(0, currentUrl.indexOf("?"))
    //   : currentUrl;
    // return this.nonGuardedRoutes.includes(current);

    let access_token = this._data.getCookiesByName("token");
    if (access_token) {
      return false;
    }
    return true;
  }

  showTutorialFab(currentUrl) {
    // console.log(currentUrl);
    let tutPresent = ["/home", "/subscriptions"];
    let current = currentUrl.substring(0, currentUrl.indexOf("?")).length
      ? currentUrl.substring(0, currentUrl.indexOf("?"))
      : currentUrl;
    return (
      !this.nonGuardedRoutes.includes(current) && tutPresent.includes(current)
    );
  }

  watchTutorial(url) {
    console.log(url);
    console.log(this._tour.isTutorialPresent(url))
    if (this._tour.isTutorialPresent(url)) {
      this.showKycTour = true;
      this.tourData = this._tour.getTourData(url);
    } else {
      this._data.toastrShow(
        "Sorry , We don't have Tutorial for this section. Please look at our FAQ for more info. You can always contact us for help , We are ready to serve you!",
        "info"
      );
    }
  }
  public themeChanged(theme: string): void {
    console.log(theme);
    this.currentTheme = theme;
  }
  setTheme(theme) {
    this.currentTheme = theme;
  }

  onTourUpdate(e) {
    console.log(e);
    this.showKycTour = e;
  }

  configureTheming() {
    console.log("Configure Theming!");
  }

  openModal() {
    const initialState = {
      title: `Create Custom Theme`,
      type: "create-custom-theme"
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-lg modal-theming"
    });
    this.bsModalRef.content.closeBtnName = "Close";
    console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe(result => {
      console.log("results", result);
      if (Object.keys(result).length) {
        console.log(result);
      }
    });
  }
}
