import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { DataService, AppService, TrackingService } from "../../services";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { first } from "rxjs/operators";
import { Role } from "../../models/Role.model";
@Component({
  selector: "app-create-user",
  templateUrl: "./create-user.component.html",
  styleUrls: ["./create-user.component.scss"],
})
export class CreateUserComponent implements OnInit {
  @Output() newUser = new EventEmitter();
  @Output() updateExistingUser = new EventEmitter();
  @Input() user: Role;
  @Input() isUpdate: boolean;
  roles: any[] = []; //all Roles
  onlyAlphabets: any = /^[^0-9 ]{1}[a-zA-Z ]*[a-zA-Z]+$/;

  emailPattern: any = "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{0}$";

  dupeUser: Role;
  selectedRole: any;

  constructor(
    private _data: DataService,
    private _app: AppService,
    public router: Router,
    private _track: TrackingService
  ) { }

  ngOnInit() {
    if (!this.isUpdate) {
      // this.user = new Role();
      this.dupeUser = new Role();
      console.log(this.dupeUser);
    } else {
      this.dupeUser = Object.assign({}, this.user);
      this.selectedRole = this.dupeUser.role["displayName"];
      console.log(this.dupeUser);
    }
    this.getRoles();
  }

  getRoles() {
    this._app.getRoles().subscribe(
      res => {
        this.roles = res["data"];
        console.log(this.roles);
      },
      err => {
        console.log(err);
      }
    );
  }

  handleUser() {
    // console.log(this.user);
    if (!this.isUpdate) {
      this.createNewUser();
    } else {
      this.updateUser();
    }
    // this.newUser.emit(this.user);
  }

  getRoleFromName(displayName) {
    let role = this.roles.filter(obj => obj.displayName == displayName);
    if (role.length) {
      return role[0];
    } else {
      return null;
    }
  }

  createNewUser() {
    this.dupeUser.role = this.getRoleFromName(this.selectedRole);
    console.log(this.dupeUser);
    this._app.createNewUser(this.dupeUser).subscribe(async res => {
      this._data.toastrShow(res["message"], "info");
      this.newUser.emit(this.dupeUser);
      await this._track.activityLog();
    });
  }

  updateUser() {
    this.dupeUser.role = this.getRoleFromName(this.selectedRole);
    let obj = {
      email: this.dupeUser["email"],
      roleSlug: this.dupeUser.role["slug"],
    };
    console.log(this.dupeUser);
    this._app.updateUser(obj).subscribe(res => {
      this._data.toastrShow(res["message"], "info");
      this.updateExistingUser.emit(this.dupeUser);
    });
  }
}
