import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

export interface ServiceReport {
  apiCalled: string;
  apiSuccessStatus: string;
  referenceId: number;
  timeStamp: string;
}
@Injectable({
  providedIn: "root",
})
export class NewReportService {
  constructor(
    private http: HttpClient,
    @Inject("API_URL") public API_URL: any
  ) {}

  getCalledServicesReportList(
    sortByData?: { startDate: any; endDate: string },
    status?: string,
    pagination: { page: number; size: number } = { page: 0, size: 20 }
  ): Observable<any> {
    let url: string = `${this.API_URL}/kyc/india/get-api-called`;

    const data = { ...pagination, sortBy: "createdDate" };
    if (sortByData && sortByData.startDate && sortByData.endDate) {
      url = `${this.API_URL}/kyc/india/sort-by-date?startDate=${sortByData.startDate}&endDate=${sortByData.endDate}`;
    }

    if (status) {
      url = `${this.API_URL}/kyc/india/sort-by-status?apiSuccessStatus=${status}`;
    }

    return this.http.post<any>(url, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  downloadReport(params: { starDate: string; endDate: string }) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'  });

  let authHttpOptions = {
      headers: headers,
      responseType: 'blob',
  };

  const data = { startDate: params.starDate, endDate: params.endDate };

  return this.http
        .post(`${this.API_URL}/kyc/india/downloadReport`, data, {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            }),
            responseType: 'blob',
        })
        .pipe(
            map((res) => {
                return res;
            })
        );
  }
}
