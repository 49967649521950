import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { DataService } from '../../../services/data.service';
import { DevOpsService } from '../services/dev-ops.service';
import { KycModalComponent } from '../../../shared/kyc-modal/kyc-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as FileSaver from 'file-saver';
@Component({
  selector: 'app-billings',
  templateUrl: './billings.component.html',
  styleUrls: ['./billings.component.scss']
})
export class BillingsComponent implements OnInit {

  showClientList=true;
  clientId;
  subscriptionDetails;
  bsModalRef          : BsModalRef;
  invoices    : any = {
    name        : 'Invoices',
    currentPage : 0 ,
    itemsPerPage: 5 ,
    totalItems  : null ,
    maxSize     : null ,
    sort        : "DESC" ,
    data        : [],
    buildQuery :  {
        'size'        : 5,
        'page'        : 0,
        'startDate'   : '',
        'orderBy' 	  : 'createdDate',
        'sort'        : 'DESC',
        'endDate'     : ''
    }    
};
unpaidInvoices    : any = {
    name        : 'Invoices',
    currentPage : 0 ,
    itemsPerPage: 5 ,
    totalItems  : null ,
    maxSize     : null ,
    sort        : "DESC" ,
    data        : [],
    buildQuery :  {
        'size'        : 5,
        'page'        : 0,
        'startDate'   : '',
        'orderBy' 	  : 'createdDate',
        'sort'        : 'DESC',
        'endDate'     : ''
    }    
}
isIndia;
negativeCons:number;
totalAmount:number;
totalUtilized:number;
utilized:number;
renew=false;
  constructor(private _devops:DevOpsService,private _data:DataService,private _app:AppService,
    private modalService: BsModalService,) { }

  ngOnInit() {
  }

  setClientID(id){
    this.clientId=id;
    this._data.changeLoaderVisibility(true);
    this._devops.getUserDetails(id).subscribe(res=>{
        if(res.data!=null){
            this.subscriptionDetails=res.data;
            this.isIndia='international'
            if(this.subscriptionDetails.currency && this.subscriptionDetails.currency=='INR'){
                this.isIndia='india'
            }
            this.negativeCons=this.subscriptionDetails.standardPricePlan-this.subscriptionDetails.negativeConsumption;
            this.totalAmount=this.subscriptionDetails.standardPricePlan+this.negativeCons;
            this.utilized=this.subscriptionDetails.standardPricePlan-this.subscriptionDetails.creditV1;
            this.totalUtilized=this.negativeCons+this.utilized;
            this.checkExpiry();
            this._data.changeLoaderVisibility(false);
           this.setInvoiceData(id);
           this.showClientList=false
        }
        else{
            this._data.changeLoaderVisibility(false);
            this._data.toastrShow(
                "No Details found for this client",
                "error"
              );
        }
         
    })
   
  }

  goBack(){
    this.showClientList=true;
  }

  markVerified(invoiceId){
    let payload={
        'internationalInvoiceNo':invoiceId,
        'invoiceVerificationStatus':'PAID'
    }
    const initialState = {
        title              : `Comments`,
        type               : "comments" ,
        backdrop           : true ,
        ignoreBackdropClick: true ,
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
        initialState,
        animated           : true,
        keyboard           : false,
        backdrop           : true,
        ignoreBackdropClick: true ,
        class              : "modal-lg"
    });

    this.bsModalRef.content.onClose.subscribe(result => {
        if(result.length>0){
            payload["invoiceVerificationStatusComment"]=result;
            this._data.changeLoaderVisibility(true);
            this._devops.updateInvoice(payload).subscribe(res=>{
                if(res.data){
                    this._data.changeLoaderVisibility(false);
                    this._data.toastrShow(
                        "Invoice has been updated successfully.",
                        "info"
                      );
                    this.setInvoiceData(this.clientId)
                }
                
            })
        }
     
    }); 
  }

  setInvoiceData(id){
    this._data.changeLoaderVisibility(true);
    this.invoices.data=[]
    this.unpaidInvoices.data=[];
   let payload={page:0,size:10}
   this._devops.getClientInvoices(id,'PAID',payload).subscribe(res=>{
    res.data.content.forEach(element => {
        if(!element.initialSetupInvoice){
            element["invoiceNo"]=element.internationalInvoiceNo
            this.invoices.data.push(element)
        }
        
    });
 })
 this._devops.getClientInvoices(id,'UNPAID',payload).subscribe(res=>{
    res.data.content.forEach(element => {
        if(element.invoiceDate){
            var invoice = new Date(element.invoiceDate); 
            var expiry = new Date(this.subscriptionDetails.endDate); 
            var Time = expiry.getTime() - invoice.getTime(); 
            var Days = Time / (1000 * 3600 * 24);
            if(Days>0){
                element["due"]='Due'
            }
            else{
                element["due"]='Over Due'
            }
        }
        if(!element.initialSetupInvoice){
            element["invoiceNo"]=element.internationalInvoiceNo
            this.unpaidInvoices.data.push(element)
        }
        
    });
    this._data.changeLoaderVisibility(false);
   })
  }

  generateInvoice(){
    this._data.changeLoaderVisibility(true)
    this._devops.generateInvoice(this.clientId).subscribe(res=>{
 
            const blob = new Blob([res], { type: 'application/pdf' });
            var file = new File([blob], "Invoice.pdf", {type:"application/pdf"});
            FileSaver.saveAs(blob, `Invoice.pdf`);
            this._data.changeLoaderVisibility(false);
            this._data.toastrShow(
                "Invoice has been generated successfully.",
                "info"
              );
              this.setInvoiceData(this.clientId)
    })
  }

  sendReminder(invoiceId){
    let payload={invoiceNo:invoiceId}
    const initialState = {
        title              : `Email`,
        type               : "send-email" ,
        backdrop           : true ,
        ignoreBackdropClick: true ,
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
        initialState,
        animated           : true,
        keyboard           : false,
        backdrop           : true,
        ignoreBackdropClick: true ,
        class              : "modal-lg"
    });
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.onClose.subscribe(result => {
        if(result.length>0){
            if(result!='default'){
                payload["email"]=result;
            }
              this._data.changeLoaderVisibility(true);
              this._devops.sendReminder(this.clientId,payload).subscribe(res=>{
             if(res.data){
                this._data.changeLoaderVisibility(false);
                this._data.toastrShow(
                    "Email Sent Successfully",
                    "info"
                  );
            }
        })
        } 
        
    });  
  }

  generateInvoicePDF(id,isReciept){
    if(this.isIndia=='india'){
        this._data.changeLoaderVisibility(true);
        this._app.generateInvoiceIndia(id)
        .subscribe(
            (res)=>{
                const blob = new Blob([res], { type: 'application/pdf' });
                var file = new File([blob], "Invoice.pdf", {type:"application/pdf"});
                FileSaver.saveAs(blob, `Invoice.pdf`);
                this._data.changeLoaderVisibility(false);
            }
        );
    }
    else{
        this._data.changeLoaderVisibility(true);
        let payload={'invoiceNo':id,typeOfInvoice:'subscription'}
        let name="Invoice.pdf"
        if(isReciept=='receipt'){
           payload['receipt']=true;
           name="Receipt.pdf"
        }
        this._app.InternationalInvoice(payload)
        .subscribe((res)=>{
            const blob = new Blob([res], { type: 'application/pdf' });
            var file = new File([blob], name, {type:"application/pdf"});
            FileSaver.saveAs(blob, name);
            this._data.changeLoaderVisibility(false);
        })
    }
    
    
}

  consumptionReport(){
    const initialState = {
        title              : `Filter`,
        type               : "date-range" ,
        size               : true,
        backdrop           : true ,
        ignoreBackdropClick: true ,
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
        initialState,
        animated           : true,
        keyboard           : false,
        backdrop           : true,
        ignoreBackdropClick: true ,
        class              : "modal-lg"
    });
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.onClose.subscribe(result => {
        if(result.endCreatedDate){
            result['page']=0;
            if(result.size==''){
                result.size=0;
             }
            this._data.changeLoaderVisibility(true);
            this._devops.consumptionReport(this.clientId,result).subscribe(res=>{
              const blob = new Blob([res], { type: 'application/pdf' });
              var file = new File([blob], "Reconciliation.pdf", {type:"application/pdf"});
              FileSaver.saveAs(blob, `Reconciliation.pdf`);
              this._data.changeLoaderVisibility(false);
            })
        }
     
    });  
    }

    checkExpiry(){
        var today = new Date(); 
        var expiry = new Date(this.subscriptionDetails.endDate);
        var Time = expiry.getTime() - today.getTime(); 
        var Days = Time / (1000 * 3600 * 24);
        if(Days<=3){
            this.renew=true;
        }
    }

    markAsPaid(invoiceId){
        let payload={
            'internationalInvoiceNo':invoiceId,
            'invoicePaymentStatus':'PAID'
        }  

        const initialState = {
            title              : `Comments`,
            type               : "comments" ,
            backdrop           : true ,
            ignoreBackdropClick: true ,
        };
        this.bsModalRef = this.modalService.show(KycModalComponent, {
            initialState,
            animated           : true,
            keyboard           : false,
            backdrop           : true,
            ignoreBackdropClick: true ,
            class              : "modal-lg"
        });

        this.bsModalRef.content.onClose.subscribe(result => {
            if(result.length>0){
                payload["invoicePaymentStatusComment"]=result;
                this._data.changeLoaderVisibility(true);
                this._devops.updateInvoice(payload).subscribe(res=>{
                    this._data.changeLoaderVisibility(false);
                    if(res.data){
                        this._data.toastrShow(
                            "Invoice has been updated successfully.",
                            "info"
                          );
                        this.setInvoiceData(this.clientId)
                    }
                    
                })
            }
         
        });  
    }

    deleteInvoice(invoiceId){
        let payload={'internationalInvoiceNo':invoiceId}
        this._data.changeLoaderVisibility(true);
                this._devops.deleteInvoice(payload).subscribe(res=>{
                    this._data.changeLoaderVisibility(false);
                    if(res.data){
                        this._data.toastrShow(
                            "Invoice has been deleted successfully.",
                            "info"
                          );
                        this.setInvoiceData(this.clientId)
                    }
                    
                })
    }
    checkInvoice(){
        this._data.changeLoaderVisibility(true)
        this._devops.checkInvoice(this.clientId).subscribe(res=>{
     
                const blob = new Blob([res], { type: 'application/pdf' });
                var file = new File([blob], "Invoice.pdf", {type:"application/pdf"});
                FileSaver.saveAs(blob, `Invoice.pdf`);
                this._data.changeLoaderVisibility(false);
    
        })
    }
}
