import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Observable, of } from "rxjs";
import { filter } from "rxjs/operators";
import { DataService } from "../services/data.service";
import { StandardService } from "../utils/standardService";

@Injectable({
  providedIn: "root"
})
export class AccessGuard implements CanActivate {
  standardService: any;
  isAllowed: any;

  accessMap: any = {
    "/theming": "theming"
    // "/bulk-upload": "bulk-upload"
  };

  isUser: any;

  constructor(private _router: Router, private data: DataService) {
    // this.data.loadClientStandardServices.subscribe(
    //    (val)=>{ this.standardServices = val; });
    this.data.loadClientData.subscribe(
    clientDataFromCookies =>{
      this.isUser = clientDataFromCookies && clientDataFromCookies["email"] ? clientDataFromCookies["email"]:"";  
    });
    console.log("::::::::::::::::::::::::::", this.isUser);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let standardService = this.data.getCookieObjectByName("standardServices");
    if (standardService) {
      this.standardService = standardService;
    } else {
      this.data.loadClientStandardServices.subscribe(val => {
        this.standardService = val;
        console.log("Inside Access Guard");
        console.log(this.standardService);
        console.log(val);
      });
    }
    let stateURL = state.url;
    console.log(state.url);
    if (this.isAccessAllowed(stateURL)) {
      return true;
    }
    this._router.navigate(["access-denied"]);
    return false;
  }

  isAccessAllowed(stateURL) {
    var presentSlug;

    if (this.accessMap.hasOwnProperty(stateURL)) {
      presentSlug = this.accessMap[stateURL];
      console.log(presentSlug);
    }

    if (!presentSlug) {
      return true;
    }

    let isPresent = this.standardService
      ? this.standardService.filter(obj => obj.slugGroup == presentSlug)
      : null;

    if (presentSlug && isPresent && isPresent.length) {
      return true;
    } else {
      return false;
    }
  }

  // canActivate(next: ActivatedRouteSnapshot , state: RouterStateSnapshot):Observable<boolean>{
  // console.log(next);
  // console.log(state);
  //       var accessibleService = this.data.getCookieObjectByName("standardServices") ;
  //       console.log(state["url"]);
  // console.log(accessibleService);
  //       console.log(this.standardService);

  // let activeRoute = state["url"] ;
  // let activeSlug = this.standardService.filter(obj=>obj.route == activeRoute);
  // if(activeSlug && activeSlug.length){
  // 	var status = accessibleService["standardServices"].filter(obj=>{
  // 		if(obj.slug == activeSlug[0].slug){
  // 			return obj
  // 		}
  // 	});
  // 	if(status && status.length){
  // 		this.isAllowed = status[0].status ;
  // 	}
  // 	console.log(status);
  // }

  ///////////////////////////////////////////////

  //   var accessibleService = this.data.getCookieObjectByName("standardServices") ;
  //   console.log(accessibleService);
  //   var activeRoute = state["url"] ;
  //   if(!accessibleService){ //If no Accessible Service Found for client , allow all access.
  //      return of(true);
  //   }
  //   else if(accessibleService){
  // var activeSlug = this.standardService.filter(obj=>obj.route == activeRoute);
  //    if(activeSlug && activeSlug.length){
  //    	var status = accessibleService["standardServices"].filter(obj=>{
  //    		if(obj.slug == activeSlug[0].slug){
  //    			return obj
  //    		}
  //    	});
  //    	if(status && status.length){
  //    		this.isAllowed = status[0].status ;
  //    	}
  //    	console.log(status);
  //    }
  //    else{
  //    	return of(true);
  //    }

  //    if(this.isAllowed == "ACTIVE"){
  //    	return of(true);
  //    }
  //    else{
  //    	 this._router.navigate(['/access-denied']);
  //    	 return of(false);
  //    }
  //   }
  // if(accessibleService){
  //        return of(true);
  //    } else {
  //        this._router.navigate(['/access-denied']);
  //        return of(false);
  //    }
  // }
}
