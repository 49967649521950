import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DevOpsService } from '../services/dev-ops.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { KycModalComponent } from "../../../shared/kyc-modal/kyc-modal.component";
import { DataService } from '../../../services';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-ocr-result',
  templateUrl: './ocr-result.component.html',
  styleUrls: ['./ocr-result.component.scss']
})
export class OcrResultComponent implements OnInit {
  @Input() data;
  @Input() endUserInfo;
  @Input() clientId;
  @Output() refresh = new EventEmitter<String>();
  ocrdata;
  shouldFlip: boolean = false;
  faceUrl;
  endUserDocInfo = null;
  checkGuid;
  overriddenBy;
  overrideComment;
  public bsModalRef: BsModalRef;
  constructor(
    private _devops: DevOpsService,
    public sanitizer: DomSanitizer,
    private _data: DataService,
    public router: Router,
    private devops: DevOpsService,
    private modalService: BsModalService
  ) {

  }

  ngOnInit() {
    this.endUserDocInfo = this.data.docProcessedInfo && this.data.docProcessedInfo.endUserDocInfo;
    this.ocrdata = this.data.docProcessedInfo && this.data.docProcessedInfo.ocrdata
    if (this.data.check) {
      this.checkGuid = this.data.check.endUserCheckID
      this.overriddenBy = this.data.check.overriddenBy
      this.overrideComment = this.data.check.overrideComment
    }

    console.log(99, this.data)
    this.faceUrl = `data:image/png;base64,${this.data.docProcessedInfo.faceUrl}`;
    this.faceUrl = this.transform(this.faceUrl);
    this.getDocFromServer();
  }
  flip() {
    this.shouldFlip = !this.shouldFlip;
  }
  async getDocFromServer() {
    const res = await this._devops.getDocFromServer(this.checkGuid, this.clientId).toPromise()
    this.endUserDocInfo["docFrontUrl"] = `data:image/jpeg;base64,${res.data["frontDoc"]
      }`;
    this.endUserDocInfo["docRearUrl"] = `data:image/jpeg;base64,${res.data["rearDoc"]
      }`;

    console.log(99, res)
  }
  transform(html) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(html);
  }
  openOverrideModel(type: any) {
    const devOpsOverride = {
      docType: 'id-checks', //this.checkGroup
      overrideType: type,
      clientId: this.clientId,
      checkGuid: this.checkGuid
    }
    const initialState = {
      title: type.replaceAll('-', ' ').toUpperCase(),
      type: 'DevOps-Override',
      devOpsOverrideData: devOpsOverride
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-lg",
    });
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.onClose.subscribe(data => {
      if (JSON.stringify(data) != '{}') {
        if (type == 'override-extraction') {
          let idDocInfo = {
            "name": data.fullName != '' ? data.fullName : data.fname + ' ' + data.lname,
            "issueDate": data.issue != '' ? data.issue : null,
            "expiryDate": data.expary != '' ? data.expary : null,
            "expiryYear": data.expiryYear != '' ? data.expiryYear : null,
            "issueYear": data.issueYear != '' ? data.issueYear : null,
            "address": data.address != '' ? data.address : null,
            "serialNo": data.sn != '' ? data.sn : null,
            "dob": data.dob != '' ? data.dob : null,
            "firstName": data.fname != '' ? data.fname : null,
            "lastName": data.lname != '' ? data.lname : null,
          }
          let payload = {
            "faceUrl": null, "idDocInfo": idDocInfo
          }
          this._data.changeLoaderVisibility(true);
          this.devops.overrideDocExtractionUserArea(this.clientId, this.checkGuid, payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(() => {
            this._data.toastrShow(`Extraction Overrided`, 'success');
          })
        }
        // else if (type == 'override-score') {

        //   let payload = {
        //     "verificationScore": data.score != '' ? data.score : null,
        //     "verificationStatus": data.status != '' ? data.status : null,
        //     "checkGuid": this.checkGuid
        //   }
        //   this._data.changeLoaderVisibility(true);
        //   this.devops.overrideScoreStatusUserArea(this.clientId, payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(() => {
        //     this._data.toastrShow(`Score Overrided`, 'success');
        //   })
        // }
        this.refresh.emit("refresh")
      }

    });
  }
}
