export const StripeKey = "pk_test_51K7FT0JTN00GakIFsr8l4vndW8dgK1O30baKedMGGFVB4fwsJgYVpoB3wd7x2ppTJwthLTYqoy1BKtcHr82H4JBs006zDemTNR" ; //DEV
//export const StripeKey = "pk_live_51K7FT0JTN00GakIFE3c3w9D1iPaIwkyndlS4PxcvCLXnnfPINQSpSXiV51spU9zZCvSKcigJImstMsCBD5lgDNrM00qGfLvwTV" ; //PROD

export const razorpayKey="rzp_test_8PjqnJb31uns6z"


export const dataSource=[{ name: 'EU Sanctions' },
{ name: 'UK HM treasury' },
{ name: 'US department of Treasury' },
{ name: 'OFAC' },
{ name: 'SECO Sanctions List' },
{ name: 'UK SANCTIONS ON RUSSIAN BANKS AND INDIVIDUALS' },
{ name: "OFAC Lists" },
{ name: "World Bank Ineligible Firms Individuals" },
{ name: "UK Sanctions List Publication" },
{ name: "US Treasury Designated Russian Oligarchs" },{ name: "Every politicians" },
{ name: "House of commons" },
{ name: "List of world leaders" },
{ name: "FRANCE SENATE LIST" },
{ name: "United Kingdom Government People" },
{ name: "Nevada Attorney General" },
{ name: "UNOPS leaders" },
{ name: "US SECURITIES AND EXCHANGE COMMISSION COMMISSIONERS" },
{ name: "Presidents of the Senate" },
{ name: "Ministers of South Africa" },{ name: "Interpol Public Red Notices" },
{ name: "DEA'S MOST WANTED" },
{ name: "FBI Wanted Terrorists" },
{ name: "ICAC Wanted Persons" },
{ name: "US Department of Transportation" },
{ name: "U.S. Department of Justice" },
{ name: "INTERPOL RED NOTICE" },
{ name: "Singapore Terrorism Suppression of Financing Act Order 2015" },
{ name: "ATF Most Wanted" }
] 