export interface IRequestOptions {
  body?: any;
  headers?: HttpHeaders | { [header: string]: string | Array<string> };
  observe?: any;
  // params?: HttpParams | { [param: string]: string | Array<string> };
  reportProgress?: boolean;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
}

export class FinancialData {
  yearEnded : number;
  totalAssets: string;
  netAssets: string;
  totalLiabilities: string;
}

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DevOpsService {

  constructor(private http: HttpClient, @Inject('API_URL') public KYS_API_URL: any) { }

  getClientList(page: number, itemsPerPage: number){
    return this.http.post(`${this.KYS_API_URL}/clients/all`, {
      "name":null,"email":null,"clientID":null,"page":page,"size":itemsPerPage,"sort":null,"clientType":null,"companyID":null
    } ).pipe(map((res: any) => res["data"]))
  }

  getIndividualOnboardingFilterByAccount(clientId, id, page, size) {
    return this.http
        .post<any>(`${this.KYS_API_URL}/kys/v2.0/tpe/devops/${clientId}/onboardingfilter/${id}`, {
            page: page,
            size: size,
            orderBy: 'createdDate',
            order: 'DESC',
        })
        .pipe(map((res) => res["data"]));
  }

  retailFilterBySearch(clientId, obj){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/tpe/devops/${clientId}/retail/onboarding/search`, obj).pipe(map((res: any) => res["data"]));
  }

  getIndividualOnboardingFilterByStatus(clientId, id, page, size) {
    return this.http
        .post<any>(`${this.KYS_API_URL}/kys/v2.0/accountinfo/devops/${clientId}/filter-by-overallstatus/${id}`, {
            page: page,
            size: size,
            orderBy: 'createdDate',
            order: 'DESC',
        })
        .pipe(map((res) => res["data"]));
  }

  getIndividualOnboardingFilterAccount(clientId, id, page, size) {
    return this.http
        .post<any>(`${this.KYS_API_URL}/kys/v2.0/accountinfo/devops/${clientId}/filter-by-accounttype/${id}`, {
            page: page,
            size: size,
            orderBy: 'createdDate',
            order: 'DESC',
        })
        .pipe(map((res) => res["data"]));
  }

  getIndividualInfoByAccountID(clientId, id) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/accountinfo/devops/${clientId}/individual/${id}`).pipe(
        map((res) => {
            return res["data"];
        })
    );
  }

  getAccountInfoById(clientId, id) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/accountinfo/devops/${clientId}/account-id/${id}`).pipe(
        map((res) => {
            return res["data"];
        })
    );
  }

  getdigitalUploadList(clientId, id) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/document/check/devops/${clientId}/get-by-account/${id}`).pipe(
        map((res) => {
            return res["data"];
        })
    );
  }

  getAllCheckByAccount(clientId, id) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/account/devops/${clientId}/allcheck/${id}`).pipe(
        map((res) => {
            return res["data"];
        })
    );
  }

  getSummary(clientId, userName: any) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/tpe/devops/${clientId}/summary/${userName}`).pipe(map((res: any) => res["data"]));
  }

  getAllStates(id){
    return this.http.get(`${this.KYS_API_URL}/provinces/country/${id}`).pipe(map((res: any) => res["data"]));
  }

  getVerifiedResult(clientId, id) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/document/check/devops/${clientId}/verification-result?kysDocumentCheckV1Id=${id}`).pipe(
        map((res) => {
            return res;
        })
    );
  }

  getDocumentVerificationResult(clientId, docId: any) {
    return this.http
        .get<number>(`${this.KYS_API_URL}/kys/v2.0/document/check/devops/${clientId}/verification-result?kysDocumentCheckV1Id=${docId}`)
        .pipe(map((res: any) => res["data"]));
}

  getDocumentUrl(clientId, url: string) {
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/document/check/devops/${clientId}/imageurl`, { s3url: url }).pipe(map((res: any) => res["data"]));
  }

  getViewDoc(clientId, id) {
    return this.http.post<any>(`${this.KYS_API_URL}/kys/v2.0/document/check/devops/${clientId}/imageurl`, id).pipe(
        map((res) => {
            return res;
        })
    );
  }

  getUploadFilesOther(clientId, obj: FormData) {
    return this.http.post<any>(`${this.KYS_API_URL}/kys/v2.0/document/check/devops/${clientId}/reupload`, obj);
  }

  getArticleByArticleId(clientId, obj) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/adverse-media/devops/${clientId}/article/${obj}`).pipe(
        map((res) => {
            return res;
        })
    );
  }

  getUserSpecificTemplate(clientId, id) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/user/risk/policy/devops/${clientId}/${id}`).pipe(
        map((res) => {
            return res;
        })
    );
  }

  reverifyDocument(clientId, documentId: string) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/document/check/devops/${clientId}/extract-and-verify${documentId}`);
  }

  getDevopsIndividualInformationByKysNo(clientId,kysNo){
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/individual/devops/${clientId}/kysNo/${kysNo}`).pipe(map((res: any) => res.data));
  }

  retailDownloadReportZip(clientId, username) {
    let headers = new HttpHeaders({
        'Content-Type': 'application/octet-stream',
    });

    let authHttpOptions = {
        headers: headers,
        responseType: 'blob',
    };

    return this.http
        .get(`${this.KYS_API_URL}/kys/v2.0/devops/${clientId}/download/${username}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/octet-stream',
            }),
            responseType: 'blob',
        })
        .pipe(
            map((res) => {
                return res;
            })
        );
  }

  getOverAllAccountStatus(clientId, accountId: string) {
    return this.http.get<string>(`${this.KYS_API_URL}/kys/v2.0/accountinfo/devops/${clientId}/overall-status/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  getOverAllFailedDocs(clientId, accountId: string) {
    return this.http.get<number>(`${this.KYS_API_URL}/kys/v2.0/accountinfo/devops/${clientId}/failed-document/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  getOverAllTotalDocs(clientId, accountId: string) {
    return this.http
        .get<number>(`${this.KYS_API_URL}/kys/v2.0/accountinfo/devops/${clientId}/total-documents-uploaded/${accountId}`)
        .pipe(map((res: any) => res["data"]));
  }

  getOverAllScore(clientId, accountId: string) {
    return this.http.get<number>(`${this.KYS_API_URL}/kys/v2.0/accountinfo/devops/${clientId}/generate-score/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  postUploadFace(payload,clientId){
    return this.http.post<any>(`${this.KYS_API_URL}/kys/v2.0/document/check/devops/${clientId}/s3/Upload`,payload)
  }

  postUpdateFace(payload,clientId){
    return this.http.post<any>(`${this.KYS_API_URL}/kys/v2.0/document/check/devops/${clientId}/update/face`,payload)
  }

  verifyDocs(clientId, checkId){
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/devops/verify-document/${clientId}/${checkId}`)
  }

  overrideVerify(clientId, checkId, payload){
    return this.http.post<any>(`${this.KYS_API_URL}/kys/v2.0/devops/override-status/${clientId}/${checkId}`, payload)
  }

  getStatusScoreUpdate(clientId, accountId){
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/devops/override-status-score/${clientId}/${accountId}`)
  }

  ittsCorporateAmlList(page: number, size: number, clientId) {
    return this.http.post<any>(`${this.KYS_API_URL}/kys/v3.0/corporate/devops/${clientId}/filter`, {
            page: page,
            size: size,
            orderBy: null,
            order: 'DESC',
    }).pipe(map((res) => res["data"]));
  }

  ittsCompanyInfo(clientID, id){
    return this.http.get(`${this.KYS_API_URL}/kys/v3.0/corporate/devops/${clientID}/company-detail/${id}`).pipe(map((res: any) => res["data"]));
  }

  ittsInvestigationDetails(clientID, id){
    return this.http.get(`${this.KYS_API_URL}/kys/v3.0/corporate/devops/${clientID}/investigation-detail/${id}`).pipe(map((res: any) => res["data"]));
  }

  ittsCompanyOverview(clientID, id){
    return this.http.get(`${this.KYS_API_URL}/kys/v3.0/corporate/devops/${clientID}/company-overview/${id}`).pipe(map((res: any) => res));
  }

  ittsNationalityRisk(clientID, id){
    return this.http.get(`${this.KYS_API_URL}/kys/v3.0/corporate/devops/${clientID}/geolocation-risk/${id}`).pipe(map((res: any) => res["data"]));
  }

  ittsAML(clientID, id){
    return this.http.get(`${this.KYS_API_URL}/kys/v3.0/corporate/devops/${clientID}/control-officer/${id}`).pipe(map((res: any) => res["data"]));
  }

  ittsFinancialData(clientID, checkListId) : Observable<FinancialData[]> {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v3.0/corporate/admin/company-financial-data/${clientID}/${checkListId}`).pipe(map((res) => res["data"]));
  }

  ittsGetRiskStatus(clientID, id){
    return this.http.get(`${this.KYS_API_URL}/kys/v3.0/corporate/devops/${clientID}/risk-details/${id}`).pipe(map((res: any) => res["data"]));
  }

  ittsAMLforCompany(clientID, id){
    return this.http.get(`${this.KYS_API_URL}/kys/v3.0/corporate/devops/${clientID}/aml/${id}`).pipe(map((res: any) => res["data"]));
  }

  ittsadverseMediaTags(clientID, id){
    return this.http.get(`${this.KYS_API_URL}/kys/v3.0/corporate/devops/${clientID}/adverse-media/${id}`).pipe(map((res: any) => res["data"]));
  }

  ittsArticle(clientID, id){
    return this.http.get(`${this.KYS_API_URL}/kys/v3.0/corporate/devops/${clientID}/article/${id}`).pipe(map((res: any) => res["data"]));
  }

  ittsDownloadReport(clientID, username) {
    let headers = new HttpHeaders({
        'Content-Type': 'application/octet-stream',
    });

    let authHttpOptions = {
        headers: headers,
        responseType: 'blob',
    };

    return this.http
        .get(`${this.KYS_API_URL}/kys/v3.0/corporate/devops/${clientID}/report/${username}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/octet-stream',
            }),
            responseType: 'blob',
        })
        .pipe(
            map((res) => {
                return res;
            })
        );
  }

  getCorporateOnboardList(page: number, size: number, clientID) {
    return this.http
        .post(`${this.KYS_API_URL}/kys/v2.0/tpe/devops/${clientID}/corporate/onboarding`, {
            page: page,
            size: size,
            orderBy: null,
            order: 'DESC',
        })
        .pipe(map((res: any) => res["data"]));
  }

  corporateFilterByStatus(clientID, obj){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/tpe/devops/${clientID}/corporate/onboarding`, obj).pipe(map((res: any) => res["data"]));
  }

  corporateFilterBySearch(clientID, obj){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/tpe/devops/${clientID}/corporate/onboarding/search`, obj).pipe(map((res: any) => res["data"]));
  }

  getThirdPartyInfo(clientID, id){
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/devops/${clientID}/tp/progress/${id}`).pipe(map((res: any) => res["data"]));
  }

  getCorporateAccountInfo(clientID, accountId: string) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/accountinfo/devops/${clientID}/corporate/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  legalOverrideCompanyOverview(clientID, accountId: any, payload: Object){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/corporate/add/company-overview/override/${clientID}/${accountId}`, payload).pipe(map((res: any) => res.data));
  }

  getSystemUbo(clientID, accountId: any) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/corporate/devops/${clientID}/control-officer/list/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  getManualUbo(clientID, accountId: any) {
      return this.http.get(`${this.KYS_API_URL}/kys/v2.0/co/devops/${clientID}/list/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  generateCorporateUbo(clientID, accountId: any) {
    return this.http
        .post(`${this.KYS_API_URL}/kys/v2.0/account/corporate/generate/control-officer/${accountId}/${clientID}`, {})
        .pipe(map((res: any) => res["data"]));
  }

  getCheckList(clientID, id: string) {
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/account/check-list/devops/${clientID}/${id}`).pipe(map((res) => res["data"]));
  }

  getAMLStatus(clientID, id){
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/co/devops/${clientID}/aml/${id}`).pipe(map((res: any) => res["data"]));
  }

  getAdverseMediaStatus(clientID, id){
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/co/devops/${clientID}/adverse-media/${id}`).pipe(map((res: any) => res["data"][0]));
  }

  getUserSpecificRiskTemplate(clientID, accountId: string) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/devops/${clientID}/user/risk-policy/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  getAllCheck(clientID, accountId: string) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/devops/${clientID}/all/check/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  getCorpSummary(clientID, userName: any) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/tpe/devops/${clientID}/summary/${userName}`).pipe(map((res: any) => res["data"]));
  }

  corpDownloadReport(clientID, username) {
    let headers = new HttpHeaders({
        'Content-Type': 'application/octet-stream',
    });

    let authHttpOptions = {
        headers: headers,
        responseType: 'blob',
    };

    return this.http
        .get(`${this.KYS_API_URL}/kys/v2.0/co/devops/${clientID}/download-document/${username}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/octet-stream',
            }),
            responseType: 'blob',
        })
        .pipe(
            map((res) => {
                return res;
            })
        );
  }

  getDocList(clientID, accountId: string) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/document/check/devops/${clientID}/digital-upload/${accountId}`).pipe(map((res: any) => res["data"]));
  }

  corpReverifyDocument(clientID, documentId: string) {
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/document/check/devops/${clientID}/extract-and-verify?kysDocumentCheckV1Id=${documentId}`);
  }

  devOpsAllStates(clientID, id){
    return this.http.get(`${this.KYS_API_URL}/provinces/devops/${clientID}/country/${id}`).pipe(map((res: any) => res["data"]));
  }

  getDocumentsInfo(clientID, id){
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/devops/${clientID}/document/info/${id}`).pipe(map((res: any) => res["data"]));
  }

  getOverallStatus(clientID, id){
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/devops/${clientID}/status/${id}`).pipe(map((res: any) => res["data"]));
  }

  getCorporateDetails(clientID, id){
    return this.http.get(`${this.KYS_API_URL}/kys/v2.0/account/devops/${clientID}/corporate/status/${id}`).pipe(map((res: any) => res["data"]));
  }

  overrideDocExtraction(id, id2,obj){
    return this.http.post(`${this.KYS_API_URL}/kys/v2.0/devops/override-doc-extraction/${id}/${id2}`, obj).pipe(
        map((res: any) => res["data"]
        ));
  }

  overrideCompanyOverview(clientID, id, object){
    return this.http.post(`${this.KYS_API_URL}/kys/v3.0/corporate/admin/company-overview/override/${clientID}/${id}`, object)
  }

  overrideFinancialData(clientID, id, object){
    return this.http.post(`${this.KYS_API_URL}/kys/v3.0/corporate/admin/submit-company-financial-data/${clientID}/${id}`, object)
  }

  clientFilterByStatus(status, obj : Object){
    return this.http.post<any>(`${this.KYS_API_URL}/clients/filter-by-status/${status}`, obj).pipe(map((res) => res["data"]));
  }

  editOption(clientID, obj: Object){
    return this.http.post<any>(`${this.KYS_API_URL}/kys/v2.0/document/check/devops/${clientID}/edit-save/`, obj);
  }

  okOption(clientID, obj: Object){
    return this.http.post<any>(`${this.KYS_API_URL}/kys/v2.0/document/check/devops/${clientID}/ok`, obj);
  }

  clientFilterByName(obj : Object){
    return this.http.post<any>(`${this.KYS_API_URL}/clients/search`, obj).pipe(map((res) => res["data"]));
  }

  clientFilterById(id, obj : Object){
    return this.http.post<any>(`${this.KYS_API_URL}/clients/search/${id}`, obj).pipe(map((res) => res["data"]));
  }


  onboardClient(clientId,id){
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/devops/${clientId}/accept-button/${id}`).pipe(map((res) => res));
  }

  rejectClient(clientId,id){
    return this.http.get<any>(`${this.KYS_API_URL}/kys/v2.0/devops/${clientId}/reject-button/${id}`).pipe(map((res) => res));
  }

  getAdverseMediaIdByName(entityName){
    return this.http.get(`${this.KYS_API_URL}/kys/v1.0/adverse-article/get/articleId?entityName=${entityName}`).pipe(map((res: any) => res.data));
  }

  addAdverseMediaArticles(entityName, clientID, payload: Object){
    return this.http.post(`${this.KYS_API_URL}/kys/v1.0/adverse-article/devops/${clientID}/add?entityName=${entityName}`, payload).pipe(map((res: any) => res.data));
  }

  addAdverseMediaEntity(articleId, clientID, payload: Object){
    return this.http.post(`${this.KYS_API_URL}/kys/v1.0/adverse-article/devops/${clientID}/add/adverse-entity/${articleId}`, payload).pipe(map((res: any) => res.data));
  }

  getAdverseMediaEntityById(articleId, clientID){
    return this.http.get(`${this.KYS_API_URL}/kys/v1.0/adverse-article/devops/${clientID}/get/adverse-entity/${articleId}`).pipe(map((res: any) => res.data));
  }

  updateAdverseMediaArticles(articleId, clientID, payload: Object){
    return this.http.put(`${this.KYS_API_URL}/kys/v1.0/adverse-article/devops/${clientID}/update/${articleId}`, payload).pipe(map((res: any) => res.data));
  }

  updateAdverseMediaEntity(articleId, clientID, oldEntityName, payload: Object){
    return this.http.put(`${this.KYS_API_URL}/kys/v1.0/adverse-article/devops/${clientID}/update/adverse-entity/${articleId}?oldEntityName=${oldEntityName}`, payload).pipe(map((res: any) => res.data));
  }

  addTagsName(entityName, clientID, payload: Object){
    return this.http.post(`${this.KYS_API_URL}/kys/v1.0/adverse-article/devops/${clientID}/tag/add?entityName=${entityName}`, payload).pipe(map((res: any) => res.data));
  }

  updateTagsName(entityName, clientID, payload: Object){
    return this.http.put(`${this.KYS_API_URL}/kys/v1.0/adverse-article/devops/${clientID}/tag/update?entityName=${entityName}`, payload).pipe(map((res: any) => res.data));
  }

  getTagsName(entityName){
    return this.http.get(`${this.KYS_API_URL}/kys/v1.0/adverse-article/get/tag?entityName=${entityName}`).pipe(map((res: any) => res.data));
  }

  getBiometricSelfie(checkGuid,clientId) {
    return this.http
      .get<any>(`${this.KYS_API_URL}/userbiometric/devops/${clientId}/doc/selfie/${checkGuid}`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getUsersScheduledAlerts(userName,data,clientId) {
    return this.http
      .post<any>(`${this.KYS_API_URL}/scheduledchecks/devops/${clientId}/${userName}`, data)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getUserNameDetails(userName,clientId) {
    return this.http
      .get<any>(`${this.KYS_API_URL}/endusers/devops/${clientId}/userdetail/${userName}`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getBasicUserInfo(userName,clientId) {
    return this.http.get<any>(`${this.KYS_API_URL}/endusers/devops/${clientId}/${userName}/info`).pipe(
      map(res => {
        return res;
      })
    );
  }

  getRedoCheckDetails(username,clientId) {
    
    return this.http.get<any>(`${this.KYS_API_URL}/userchecks/devops/${clientId}/pre/redo-check/${username}`).pipe(
      map(res => {
        return res;
      })
    );
  }

  getDocFromServer(checkGuid,clientId) {
    return this.http.get<any>(`${this.KYS_API_URL}/userdocs/devops/${clientId}/${checkGuid}/doc`).pipe(
      map(res => {
        return res;
      })
    );
  }

  overrideDocExtractionUserArea(clientId,checkguid,payload){
    return this.http.post(`${this.KYS_API_URL}/kyc-hub/devops/override/doc-extraction/${clientId}/${checkguid}`, payload).pipe(
        map((res: any) => res["data"]
        ));
  }

  overrideScoreStatusUserArea(clientId,payload){
    return this.http.post<any>(`${this.KYS_API_URL}/kyc-hub/devops/${clientId}/override/status-score/`, payload)
  }

  editIndividualUserArea(clientId,payload){
    return this.http.post<any>(`${this.KYS_API_URL}/kyc-hub/devops/${clientId}/edit`, payload);
  }

  overrideIndividualStatusUserArea(clientId,payload){
    return this.http.post<any>(`${this.KYS_API_URL}/kyc-hub/devops/${clientId}/ok`, payload);
  }

  getClientInvoices(clientId,type,payload){
    return this.http.post<any>(`${this.KYS_API_URL}/kyc/india/sales/international/get-all-invoices/${clientId}/${type}`,payload).pipe(  
      map(res => {  
        return res;  
      })  
    );  
  }

  getUserDetails(clientId){
    return this.http.get<any>(`${this.KYS_API_URL}/kyc/india/sub-customers-id/${clientId}`).pipe(
      map(res => {
        return res;
      })
    );
  }

  updateInvoice(payload){
    return this.http.post<any>(`${this.KYS_API_URL}/kyc/india/sales/international/update-invoices`,payload).pipe(  
      map(res => {  
        return res;  
      })  
    );  
  }

  generateInvoice(id){
    return this.http.post(`${this.KYS_API_URL}/kyc/india/sales/international/generate-invoice/${id}`,null,{
      headers: new HttpHeaders({  
        "Content-Type": "application/json",  
      }),  
      responseType: "blob", 
      }).pipe(  
      map(res => {  
        return res;  
      })  
    );  
  }

  checkInvoice(id){
    return this.http.post(`${this.KYS_API_URL}/kyc/india/sales/international/check-invoice/${id}`,null,{
      headers: new HttpHeaders({  
        "Content-Type": "application/json",  
      }),  
      responseType: "blob", 
      }).pipe(  
      map(res => {  
        return res;  
      })  
    );
  }

  sendReminder(id,invoice){
    return this.http.post<any>(`${this.KYS_API_URL}/kyc/india/sales/international/send-invoice-reminder/${id}`,invoice).pipe(  
      map(res => {  
        return res;  
      })  
    );  
  }

  consumptionReport(id,payload){
    return this.http.post(`${this.KYS_API_URL}/client/consumption/report/${id}`,payload,{
      headers: new HttpHeaders({  
        "Content-Type": "application/json",  
      }),  
      responseType: "blob", 
      }).pipe(  
      map(res => {  
        return res;  
      })  
    );  
  }

  overallconsumptionReport(payload){
    return this.http.post(`${this.KYS_API_URL}/client/consumption/report/over-all`,payload,{
      headers: new HttpHeaders({  
        "Content-Type": "application/json",  
      }),  
      responseType: "blob", 
      }).pipe(  
      map(res => {  
        return res;  
      })  
    );  
  }

  deleteInvoice(payload){
    return this.http.post<any>(`${this.KYS_API_URL}/kyc/india/sales/international/delete-invoice`,payload).pipe(  
      map(res => {  
        return res;  
      })  
    );  
  }
  getImages(id) {
    return this.http.get<any>(`${this.KYS_API_URL}/userdocs/${id}/doc`).pipe(
      map(res => {
        return res;
      })
    );
  }
}

