export class State{
	standardCheckGroup: any;
	stepNo            : any;
	next              : any;
	previous          : any;
	isFinalStep       : any;
}

import { Component, OnInit , Input , Output , EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService, AppService } from "../../../services/";
import { first } from 'rxjs/operators';
import { DomSanitizer} from '@angular/platform-browser';
import {MatchAttributeStatus , VerificationStates} from "../../../utils/status";
import {Verification} from "../../../models/Verification.model";

@Component({
  selector: 'app-verification-result',
  templateUrl: './verification-result.component.html',
  styleUrls: ['./verification-result.component.scss']
})
export class VerificationResultComponent implements OnInit {

	@Input() verification         : Verification ;
	@Input() endUserDocInfo       : any ;
	@Input() ocrdata              : any ;
	@Input() faceUrl              : any ;
	@Input() checkGroup           : any ;  //id-checks , address-checks , aml-checks
	
	@Input() correspondenceAddress: any ;
	@Input() permanentAddress     : any ;
	
	@Input() traversalState       : any;
	
	@Output() checkState          : EventEmitter<any> = new EventEmitter();

	@Input() companiesData : any ;

	knOptions = {
		readOnly: true,
		size: 140,
		unit: "%",
		textColor: "#ffffff",
		fontSize: "18",
		fontWeigth: "400",
		fontFamily: "Roboto",
		valueformat: "percent",
		value: 0,
		max: 250,
		trackWidth: 15,
		barWidth: 15,
		trackColor: "#e1e1e1",
		barColor: "#ff0000"
	};

	constructor(
		private _data: DataService,
		private _app : AppService,
		public sanitizer:DomSanitizer,
		public router: Router){
	}

	ngOnInit() {
		// console.log(this.verification);
		if(this.checkGroup == 'id-checks' || this.checkGroup == 'id-checks-2'){
			// console.log(this.faceUrl);
			this.faceUrl = this.transform(this.faceUrl);
			// console.log(this.ocrdata);
			this.getDocFromServer();
		}

		if(this.checkGroup == 'address-checks'){
			console.log(this.ocrdata);
			this.getDocFromServer();
		}
	}

	getDocFromServer(){
		let checkGuid = this.endUserDocInfo.endUserCheck["endUserCheckID"];
		this._app.getDocFromServer(checkGuid)
		.subscribe(
			(res)=>{
				this.endUserDocInfo["docFrontUrl"] = `data:image/jpeg;base64,${res.data["frontDoc"]}`;
				this.endUserDocInfo["docRearUrl"] = `data:image/jpeg;base64,${res.data["rearDoc"]}`; 
			}
		);
	}

	transform(html) {
    	return this.sanitizer.bypassSecurityTrustResourceUrl(html);
  	}

	getVerificationText(key , value , isIcon?:any){
		let matched =  VerificationStates.filter((obj)=>{
			if(obj.field == key && obj.status == value){
				return obj;
			}
		});
		if(matched.length){
			return isIcon ? matched[0].icon : matched[0].text ;
		}
		else{
			return isIcon ? "fa-exclamation-circle text-warning" : `${key} : Not Found` ;
		}
	}

	goToStep(stepValue){
		let state =  this.traversalState.filter(obj=>obj.standardCheckGroup == this.checkGroup);
		if(state.length){
			console.log(this.traversalState);
			console.log(state[0]);
			let traversalState = {
				"allowedState" : state[0] ,
				"goToStep" : stepValue
			}
			this.checkState.emit(traversalState);
		}
		// this.checkState.emit(this.traversalState);
	}

	hasButton(value){
		//value = previous , next & isFinalStep
		let check : State[] =  this.traversalState.filter(obj=>obj.standardCheckGroup == this.checkGroup);
		if(check.length){
			// let param = check[0].next ? value == "next" : (check[0].previous ? value == "previous" : check[0].isFinalStep);
			if(value == "next"){
				return check[0].next ;
			}
			if(value == "previous"){
				return check[0].previous ;
			}
			if(value == "isFinalStep"){
				return check[0].isFinalStep ;
			}
		}
	}

}
