import { Component, OnInit } from "@angular/core";
import { DataService, PaymentService, AppService, AuthService } from "../../../services";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { first } from "rxjs/operators";
import { termsAndCondition } from "../../../utils/terms-and-condition";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { KycModalComponent } from "../../../shared/kyc-modal/kyc-modal.component";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  accountLogin: {
    username: string;
    password: string;
  } = {
    username: "",
    password: ""
  };

  returnURL: string = null;
  clientData : any;
  //Modal
  bsModalRef: BsModalRef;
  termsAndCondition = termsAndCondition;

  constructor(
    private modalService: BsModalService,
    private _data: DataService,
    private _auth: AuthService,
    private _payment: PaymentService,
    private route: ActivatedRoute,
    public router: Router,
    private _app: AppService
  ) {
    this.route.queryParams.subscribe(params => {
      console.log("params");
      if (params && Object.keys(params).length) {
        this.returnURL = params["returnURL"];
      }
      console.log(params);
    });
  }

  ngOnInit() {}

  doLogin() {
    this.accountLogin.username = this.accountLogin.username.trim();
    this.accountLogin.password = this.accountLogin.password.trim();
    console.log("::::::::::::::::::", this.accountLogin);
    this._data.changeLoaderVisibility(true);
    this._auth
      .doLogin(this.accountLogin)
      .pipe(first())
      .subscribe(
        res => {
          console.log(res),
            this._data.changeLoaderVisibility(false),
            this._data.setCookieByName("token", res["access_token"]);
          // this.getClientSubscriptionInfo();
          this.getCurrentSubscription();
          this.getClientProfileData();
          this.getClientConfig()
          this._data.toastrShow("Logged In!", "info");
          if (!this.returnURL) {
            this.router.navigate(["/home"]);
          } else {
            this.router.navigate([`${this.returnURL}`]);
          }
        }
        // (err)=>{console.log('err') , this._data.toastrShow("Invalid Credentials" , "error")},
      );
  }

  getClientConfig(){
    this._app.getClientConfig().subscribe(res => {
      if(res.data){
        this._data.clientsConfigs.next(res.data);
      }
    });
  }

  getClientSubscriptionInfo() {
    this._app.getClientSubscriptionInfo().subscribe(res => {
      let accessibleStandardServices =
        res.data.subscriptionPlan.standardServices;
      if (accessibleStandardServices && accessibleStandardServices.length) {
        this._data.setCookieByName(
          "planCategory",
          res.data.subscriptionPlan.planCategory
        );
        console.log(res.data.subscriptionPlan.planCategory);
        console.log(accessibleStandardServices);
        this._data.changeClientStandardServices(accessibleStandardServices);
      }
    });
  }

  getCurrentSubscription(){
    this._payment.getCurrentSubscription().subscribe( res => {
        console.log(' Current SUbscription : ',res);
        if(res.data) this._data.setCookieObject('licence' , true);
        let accessibleStandardServices = (res.data) ? res.data.subscriptionPlan.standardServices : [];
        let accessibleStandardCheck = (res.data) ? res.data.subscriptionPlan.standardCheck : [];
        if (accessibleStandardServices && accessibleStandardServices.length) {
          this._data.changeClientStandardServices(accessibleStandardServices);
          this._data.changeClientStandardChecks(accessibleStandardCheck);
          console.log(
            ":::::::::::::::::::::::::::::::::",
            res.data.subscriptionPlan.planCategory
          );
          console.log(accessibleStandardServices);
        }
    })
  }
  getClientProfileData() {
    this._app.getClientProfileData().subscribe(res => {
      this.clientData = res.data;
      console.log(' Client Profile Data : ' , res.data);
      if(!this.clientData.termsAndConditionAccepted){
        const initialState = {
                title   : `Terms And Condition`,
                type    : "terms-and-condition" ,
                termsAndCondition : termsAndCondition
          };
        this.bsModalRef = this.modalService.show(KycModalComponent, {
              initialState,
              class: "modal-lg",
              backdrop: 'static'
        });
        this.bsModalRef.content.closeBtnName = "Close";
        this.bsModalRef.content.onClose.subscribe(isTermsAndCondition => {
          console.log(isTermsAndCondition);
          if(isTermsAndCondition == "enabled"){
            this._app.acceptTermsAndCondition().subscribe(res => {
              this.clientData.termsAndConditionAccepted = true;
              this._data.changeClientData(this.clientData);
            })
          }
        });
      }else{
        this._data.changeClientData(this.clientData);
      }
      // console.log(res.data);
    });
  }
}
