import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ObjArrPipe } from "./obj-arr.pipe";
import { KeyValPipe } from "./key-val.pipe";
import { UiNamePipe } from './ui-name.pipe';
import { ScoreRiskStatusPipe } from './score-risk-status.pipe';

@NgModule({
  declarations: [
  	ObjArrPipe,
  	KeyValPipe,
  	UiNamePipe,
   ScoreRiskStatusPipe
  ],
  imports: [
    CommonModule
  ],
  exports : [
  	ObjArrPipe,
  	KeyValPipe,
  	UiNamePipe,
    ScoreRiskStatusPipe
  ]
})
export class PipesModule { }
