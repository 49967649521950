import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from "@angular/common/http";
import { Observable } from "rxjs";
import { DataService } from "../services/data.service";
@Injectable()
export class ICOInterceptor implements HttpInterceptor {
  constructor(private _data: DataService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headersConfig = {
      Accept: "application/json"
    };
    headersConfig["appsource"] = `DASHBOARD`;
    let access_token = this._data.getCookiesByName("token"); //multipart/form-data

    //for bulk upload ui
    if (request.url.indexOf("bulk/upload") > 0) {
      console.log("AAAAAAAAAAAAAAAAAAAAAAA");
      headersConfig["Authorization"] = `Bearer ${access_token}`;
    }
    else if (request.url.indexOf("getaquote") > 0) {
      console.log("AAAAAAAAAAAAAAAAAAAAAAA");
      headersConfig["Authorization"] = `Bearer ${access_token}`;
    }
    else if (request.url.indexOf("oauth/token") > 0) {
      console.log("BBBBBBBBBBBBBBBBBBBBBB");
      headersConfig["Content-Type"] = `application/x-www-form-urlencoded`;
      headersConfig[
        "Authorization"
      ] = ` Basic NWZlNTk3NWRlMzE3YmQ4MmNkMTEzMDQ1ZTExYzk5OTM1ZjBkNmI3YTA3N2EzMGRlYjg2MzliOTMyOTY2M2UzZjplZjM4Mjk1NTg0ZTJiMjNiMmY4YzU0OGFjODFmYTNhODM3NmJlMTUxYzdkNmQ0OWY5OTg1ZjEwNzgwNTdjMzM5`;
    }

    else if (request.url.indexOf("video") > 0) {
      headersConfig["Authorization"] = `Bearer ${access_token}`;
      headersConfig["Content-Type"] = "application/octet-stream";
      // console.log("Video Here");
      // console.log(request);
    }
    else if (request.url.indexOf("sample-csv/download") > 0) {
      headersConfig["Accept"] = 'text/csv';
      headersConfig["Authorization"] = `Bearer ${access_token}`;
    }
    else if (request.url.indexOf("-token") > 0) {

    }
    else if (request.url.indexOf("contactus") > 0) {

    } else if (
      request.url.indexOf("public/check") > 0 ||
      request.url.indexOf("public/price") > 0 ||
      request.url.indexOf("public/register") > 0
    ) {

      headersConfig[
        "Authorization"
      ] = ` Basic NWZlNTk3NWRlMzE3YmQ4MmNkMTEzMDQ1ZTExYzk5OTM1ZjBkNmI3YTA3N2EzMGRlYjg2MzliOTMyOTY2M2UzZjplZjM4Mjk1NTg0ZTJiMjNiMmY4YzU0OGFjODFmYTNhODM3NmJlMTUxYzdkNmQ0OWY5OTg1ZjEwNzgwNTdjMzM5`;
    } else if (request.url.indexOf("public/pay") > 0) {

      headersConfig[
        "Authorization"
      ] = ` Basic NWZlNTk3NWRlMzE3YmQ4MmNkMTEzMDQ1ZTExYzk5OTM1ZjBkNmI3YTA3N2EzMGRlYjg2MzliOTMyOTY2M2UzZjplZjM4Mjk1NTg0ZTJiMjNiMmY4YzU0OGFjODFmYTNhODM3NmJlMTUxYzdkNmQ0OWY5OTg1ZjEwNzgwNTdjMzM5`;
    } else if (access_token && request.url.indexOf("bulk/upload") > 0) {
      headersConfig["Authorization"] = `Bearer ${access_token}`;


      // headersConfig["Content-Type"] = "application/octet-stream" ;
    } else if (
      request.url.indexOf("clients/register") > 0 ||
      request.url.indexOf("resetpassword") > 0 ||
      request.url.indexOf("setupaccount") > 0 ||
      request.url.indexOf("resetpasswordlink") > 0
    ) {
      headersConfig[
        "Authorization"
      ] = ` Basic NWZlNTk3NWRlMzE3YmQ4MmNkMTEzMDQ1ZTExYzk5OTM1ZjBkNmI3YTA3N2EzMGRlYjg2MzliOTMyOTY2M2UzZjplZjM4Mjk1NTg0ZTJiMjNiMmY4YzU0OGFjODFmYTNhODM3NmJlMTUxYzdkNmQ0OWY5OTg1ZjEwNzgwNTdjMzM5`;

    }
    else if (request.url.indexOf("/kyc/document-verification") > 0) {
      console.log("conn")
      headersConfig["Authorization"] = `Bearer ${access_token}`;
    }
    else if (request.url.indexOf("bulk/upload") > 0) {
      headersConfig["Content-Type"] = `application/x-www-form-urlencoded`;
      headersConfig[
        "Authorization"
      ] = ` Basic NWZlNTk3NWRlMzE3YmQ4MmNkMTEzMDQ1ZTExYzk5OTM1ZjBkNmI3YTA3N2EzMGRlYjg2MzliOTMyOTY2M2UzZjplZjM4Mjk1NTg0ZTJiMjNiMmY4YzU0OGFjODFmYTNhODM3NmJlMTUxYzdkNmQ0OWY5OTg1ZjEwNzgwNTdjMzM5`;
    }
    else if (request.url.indexOf("country/all") > 0) {
      // No Auth is required
    }
    else {
      headersConfig["Authorization"] = `Bearer ${access_token}`;
    }

    const xsrfcokkie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("XSRF-TOKEN="))
    if (xsrfcokkie && request.method !== 'GET') {
      headersConfig["X-XSRF-TOKEN"] = xsrfcokkie?.split("=")[1];
    }
    // console.log(request);

    if (request.url.includes("api.bigdatacloud.net")) {
      request = request.clone({
        setHeaders: headersConfig,
      });
    }
    else {
      request = request.clone({
        setHeaders: headersConfig,
        withCredentials: true
      });
    }

    return next.handle(request);
  }
}
