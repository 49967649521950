import { Component, OnInit } from "@angular/core";
import { BulkUploadService } from "../bulk-upload.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { KycModalComponent } from "../../../shared/kyc-modal/kyc-modal.component";

import { DataService, TrackingService } from "../../../services";

@Component({
  selector: "app-batch-details",
  templateUrl: "./batch-details.component.html",
  styleUrls: ["./batch-details.component.scss"],
})
export class BatchDetailsComponent implements OnInit {

  batchDetailData: any[] = [];
  batchName: any;
  bsModalRef: BsModalRef;

  id: any;
  status: any;

  //file uploaded
  uploadedFile: any;
  fileReupload: any = 'false';

  //upload response data
  uploadResponse: any;

  extAllowed: any[] = ["text/csv", "application/vnd.ms-excel"];

  //Pagination
  currentPage: number = 0;
  itemsPerPage: number = 5;
  totalItems: number;
  maxSize: number;
  sort: string = null;

  buildQuery: any = {
    'batchId': null,
    'size': this.itemsPerPage,
    'page': this.currentPage,
    'sort': this.sort
  };

  isApiLoading: boolean = false;

  isFileLoaded: boolean = false;
  checksArrayList: any = [
    { id: '1', name: 'PEP', isChecked: false, value: "pep-check" },
    { id: '2', name: 'Sanction', isChecked: false, value: "sanctions-check" },
    { id: '3', name: 'Crime', isChecked: false, value: "crime-check" },
    { id: '4', name: 'Adverse Media', isChecked: false, value: "adverse-media" },

  ]
  scheduling = {
    schType: '',
    date: '',
    type: '',
    from: '',
    to: ''
  }
  checksArray: string[] = [];
  constructor(
    private modalService: BsModalService,
    private _bulkUp: BulkUploadService,
    private route: ActivatedRoute,
    public router: Router,
    private _data: DataService,
    private _track: TrackingService
  ) { }

  ngOnInit() {
    //get id and status
    this.route.params.subscribe(params => {
      this.id = params["id"];
      // this.status = params["status"];
      console.log(params)
    });

    this.route.queryParams.subscribe(params => {
      this.status = params['status'];
    });

    console.log(this.status);

    this.getBatchDetails(this.id);
  }

  ngOnDestroy() {
    console.log("destroyed!");
    if (this.bsModalRef) {
      this.bsModalRef.content.onClose.subscribe(result => {
        console.log(result);
      });
      this.bsModalRef.hide();
    }
  }
  onsch() {
    console.log(this.scheduling, this.checksArray)
  }
  componentMethodName(event: any, isChecked: boolean) {
    if (isChecked) {
      this.checksArray.push(event.target.value)
    }
    else {
      let index = this.checksArray.indexOf(event.target.value);
      this.checksArray.splice(index, 1);
    }
    console.log(this.checksArray)
    // this.bulkUploadForm.value.checksSelected = this.checksArray;
  }
  uploadNewCsv() {
    this.batchDetailData = [];
    this.fileReupload = 'true';
  }
  notif() {
    this.router.navigate([`/bulk-upload/${this.id}/notification-log`], { queryParams: { status: this.status } })
  }
  //get batch details
  getBatchDetails(id) {
    this.isApiLoading = true;
    this._data.changeLoaderVisibility(true);
    this.buildQuery["batchId"] = id;
    this._bulkUp.getBatchDetails(id, this.buildQuery).subscribe(
      res => {
        this.isApiLoading = false;
        this._data.changeLoaderVisibility(this.isApiLoading);
        console.log(res.data.content);
        let batchDetailData = res.data.content;
        if (batchDetailData && batchDetailData.length) {
          this.batchName = batchDetailData[0].bulkUploadBatch.batchName;
        }
        else {
          this.batchName = "No Records Found";
        }
        this.batchDetailData = [];
        for (var i = 0; i < batchDetailData.length; i++) {
          if (typeof (batchDetailData[i].validation) == 'string') {
            batchDetailData[i].validation = JSON.parse(batchDetailData[i].validation);
          }
          this.batchDetailData.push(batchDetailData[i]);
        }
        if (this.currentPage === 0) {
          this.totalItems = res.data.totalElements;
          this.maxSize = res.data.totalPages;
        }
        else {
          this.isApiLoading = false
        }
        console.log(this.batchDetailData);
      },
      err => { }
    );
  }

  getPaginatedText() {
    let a = (this.currentPage * this.itemsPerPage) + 1;
    let b;
    if (this.currentPage < (this.maxSize - 1)) {
      b = (this.currentPage + 1) * this.itemsPerPage;
    }
    else if (this.currentPage == (this.maxSize - 1)) {
      // let bufferCheck = this.itemsPerPage * this.maxSize 
      b = this.totalItems;
    }
    return a + "-" + b;
  }

  getPaginatedData(isNext) {
    console.log("getMoreUsersData");
    this.currentPage = isNext ? this.currentPage + 1 : this.currentPage - 1;
    this.buildQuery["page"] = isNext ? this.buildQuery["page"] + 1 : this.buildQuery["page"] - 1;;
    if (this.currentPage < this.maxSize) {
      this.getBatchDetails(this.id);
    }
  }

  //get file
  fileChangeListener($event) {
    // this.uploadedFile = $event[0] ? $event[0] : $event.target.files[0];
    let uploadedFile = $event[0] ? $event[0] : $event.target.files[0];
    console.log(uploadedFile);
    console.log(uploadedFile.type);
    if (uploadedFile && this.extAllowed.indexOf(uploadedFile.type) >= 0) {
      var reader = new FileReader();
      this.uploadedFile = uploadedFile;
      this.isFileLoaded = true;
    }
    else {
      // this.isFileLoaded = false;
      this._data.toastrShow("File extension not supported , we support only csv.", "error");
    }
  }

  //upload file to server
  uploadFile(e) {
    // convert data to form data and upload
    this._data.changeLoaderVisibility(true);
    let formData = new FormData();
    formData.append("file", this.uploadedFile);
    formData.append("batchId", this.id);
    formData.append("reuploadFile", this.fileReupload);
    console.log(formData);
    if (e === "firstUpload") {
      this._bulkUp.uploadFile(formData).subscribe(
        async res => {
          console.log(res);
          await this._track.activityLog();
          this._data.changeLoaderVisibility(false);
          this.getBatchDetails(this.id);
        },
        err => {
          this._data.changeLoaderVisibility(false);
        }
      );
    }
  }
  goback() {
    this.router.navigate([`/bulk-upload`])
  }
  //open record edit modal
  editRecord(recordTobeEdited) {
    // console.log(recordTobeEdited);
    //open a modal
    const initialState = {
      title: `Edit Record`,
      type: "edit-bulk-upload-record",
      recordTobeEdited,
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      animated: true,
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result && Object.keys(result).length) {
        console.log(result);
        this.getBatchDetails(this.id);
      }
    });
  }

  //final submit modal
  finalSubmit(recordTobeEdited) {
    //get total credits required
    this._data.changeLoaderVisibility(true);
    this._bulkUp.getCreditRequired(this.id).subscribe(
      res => {
        this._data.changeLoaderVisibility(false);
        let totalCredit =
          res.data.id +
          res.data.id_totalCredit +
          res.data.pep +
          res.data.pep_totalCredit +
          res.data.sanction +
          res.data.sanction_totalCredit +
          res.data.crime +
          res.data.crime_totalCredit +
          res.data.media +
          res.data.media_totalCredit +
          res.data.selfie +
          res.data.selfie_totalCredit +
          res.data.liveness +
          res.data.liveness_totalCredit;

        let creditLeftPayAsYouGo = res.data.totalAvilableCreditPayAsYouGo;
        let creditLeftSubscription = res.data.totalAvilableCreditPayAsYouGo;
        console.log("totalCreditRequired: ", totalCredit);

        //modal control
        // open a modal
        const initialState = {
          title: `Edit Record`,
          type: "final-submit-confirmation",
          totalCredit,
          creditLeftPayAsYouGo,
          creditLeftSubscription,
        };
        this.bsModalRef = this.modalService.show(KycModalComponent, {
          initialState,
          animated: true,
          keyboard: false,
          backdrop: true,
          ignoreBackdropClick: true,
          class: "modal-lg",
        });
        this.bsModalRef.content.closeBtnName = "Close";
        this.bsModalRef.content.onClose.subscribe(result => {
          if (result && Object.keys(result).length) {
            console.log(result);

            if (result.shouldPay) {
              this._data.changeLoaderVisibility(true);
              this._bulkUp.finalSubmit(this.id).subscribe(
                async res => {
                  console.log(res);
                  this._data.changeLoaderVisibility(false);
                  await this._track.activityLog();
                  this._data.toastrShow("Successfully Created Batch", "info");
                  this.router.navigate([`/user-home`]);
                },
                err => { }
              );
            }
            //call final submission api
          }
        });
      },
      err => { }
    );
  }


}
